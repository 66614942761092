import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostBinding,
    Input,
    OnChanges,
    SimpleChanges,
    ViewEncapsulation,
} from '@angular/core';
import { NavigationAppearance, NavigationItem, NavigationMode, NavigationPosition, } from './navigation.types';
import { Router } from '@angular/router';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Animations } from '@app/shared/animations';
import { TranslateService } from "@ngx-translate/core";


@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
    animations: Animations,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationComponent implements OnChanges {
    @Input() mode: NavigationMode = NavigationMode.side;
    @Input() position: NavigationPosition = NavigationPosition.start;
    @Input() appearance: NavigationAppearance = NavigationAppearance.default;
    @Input() navigationItems!: NavigationItem[];
    @Input() opened = true;
    activeAsideIndex = -1;
    private _animationsEnabled = false;

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _elementRef: ElementRef,
        private _router: Router,
        private _translationService: TranslateService,
    ) {
    }

    @HostBinding('class') get classList() {
        return {
            'navigation-animations-enabled': this._animationsEnabled,
            [`navigation-appearance-${this.appearance}`]: true,
            [`navigation-position-${this.position}`]: true,
            [`navigation-mode-${this.mode}`]: true,
            'navigation-opened': this.opened,
            'navigation-aside-opened': this.activeAsideIndex > -1,
        };
    }

    @HostBinding('style') get styleList() {
        return {
            visibility: this.opened ? 'visible' : 'hidden',
        };
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('opened' in changes) {
            this.opened = coerceBooleanProperty(changes['opened'].currentValue);
            this._toggleOpened(this.opened);
        }
    }

    trackByFn(index: number, item: NavigationItem): string {
        return item.id ?? '';
    }

    asideClose(): void {
        this.activeAsideIndex = -1;
    }

    toggleMenuPreview(index: number): void {
        if (this.activeAsideIndex === index) {
            this.activeAsideIndex = -1;
        } else {
            this.activeAsideIndex = -1;
            setTimeout(() => {
                this.activeAsideIndex = index;
                this._changeDetectorRef.detectChanges();
            }, 10);

        }
    }


    private _toggleOpened(open: boolean): void {
        this.opened = open;
        this._enableAnimations();
        if (this.mode === NavigationMode.over) {
            this.activeAsideIndex = 0;
        }
    }

    private _enableAnimations(): void {
        if (this._animationsEnabled) {
            return;
        }
        this._animationsEnabled = true;
    }
}
