import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent {
  @Input() color:
    | 'white'
    | 'primary-8-percent'
    | 'primary-8-percent-bordered'
    | 'primary-25-percent'
    | 'white-bordered' = 'white';
  @Input() hasError: boolean = false;
  @Input() padding: '20px' | '30px' | '40px' = '30px';

  @Input() classes: string = '';
  @Input() hasElevation: boolean = false;
}
