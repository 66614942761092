import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-geo-loader',
    templateUrl: './geo-loader.component.html',
    styleUrls: ['./geo-loader.component.scss']
})
export class GeoLoaderComponent {
  @Input() showBg? = false;
  @Input() textToDisplay? = '';
  @Input()  isWhite = false;
}
