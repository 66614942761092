import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { environment } from '@environments/environment';
import { ZohoApiService } from '@app/shared/services/zoho.api.service';
import { tap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ZohoService {
  private renderer: Renderer2;
  private mainScript = 'zohodeskasap';
  env = JSON.parse(JSON.stringify(environment));
  private projectId = this.env.zoho_project_id;
  private orgId = this.env.zoho_org_id;
  private currentASAPLang: string | undefined;

  constructor(
    private rendererFactory: RendererFactory2,
    private zohoApiService: ZohoApiService
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  initZohoAsap() {
    if (!document.getElementById(this.mainScript)) {
      return this.zohoApiService.getZohoJwt().pipe(
        tap((token) => {
          const nonce = window.crypto.getRandomValues(new Uint8Array(16));
          const nonceBase64 = btoa(String.fromCharCode(...nonce));

          const scriptContent = `
                var d=document;s=d.createElement("script"),s.type="text/javascript",s.id="zohodeskasapscript",s.defer=!0,s.nonce="${nonceBase64}",s.src="https://desk.zoho.com/portal/api/web/asapApp/${this.projectId}?orgId=${this.orgId}",t=d.getElementsByTagName("script")[0],t.parentNode.insertBefore(s,t),window.ZohoDeskAsapReady=function(s){var e=window.ZohoDeskAsap__asyncalls=window.ZohoDeskAsap__asyncalls||[];window.ZohoDeskAsapReadyStatus?(s&&e.push(s),e.forEach(s=>s&&s()),window.ZohoDeskAsap__asyncalls=null):s&&e.push(s)};
            `;

          const scriptElement = this.renderer.createElement('script');
          this.renderer.setAttribute(scriptElement, 'id', this.mainScript);
          this.renderer.appendChild(
            scriptElement,
            this.renderer.createText(scriptContent)
          );
          this.renderer.appendChild(document.body, scriptElement);

          window.ZohoDeskAsapReady(() => {
            window.ZohoDeskAsap.invoke('login', (callback: Function) => {
              callback(token);
            });
          });
        })
      );
    } else {
      return EMPTY;
    }
  }

  onZohoASAPButtonClickHandler(): void {
    const observer = new MutationObserver(() => {
      const ASAPButton = document.getElementById('zohohc-asap-web-button');

      if (ASAPButton) {
        this.renderer.listen(ASAPButton, 'click', () => {
          setTimeout(() => {
            this.changeASAPLang();
          }, 800);
        });

        observer.disconnect();
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });
  }

  changeASAPLang(): void {
    const ASAPLangButton = document.querySelector(
      '.zd-asap-languagepopup-language__choose .zd-asap-newtext-common'
    ) as HTMLElement;

    if (!ASAPLangButton) return;

    const ASAPLangButtonText = ASAPLangButton?.innerText || '';
    this.currentASAPLang = ASAPLangButtonText === 'En' ? 'en' : 'ar';

    if (this.currentASAPLang !== window.LANG) {
      const ASAPLangList = document.querySelectorAll('.custom_list_item');
      if (window.LANG === 'en') {
        (ASAPLangList.item(1) as HTMLElement).click();
      } else {
        (ASAPLangList.item(0) as HTMLElement).click();
      }
    }
  }

  logout(): void {
    window.ZohoDeskAsapReady(() => {
      window.ZohoDeskAsap.invoke('logout');
    });
  }
}
