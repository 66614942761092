import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import * as fromAuth from '@app/shared/store/auth/auth.reducer';
import {EffectsModule} from "@ngrx/effects";
import {AuthEffects} from "@app/shared/store/auth/auth.effects";

@NgModule({
  imports: [
    StoreModule.forFeature(fromAuth.authKey, fromAuth.authReducer),
    EffectsModule.forFeature([AuthEffects])
  ]
})
export class AuthStoreModule {}
