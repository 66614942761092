import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { NotFoundComponent } from '@app/core/components/not-found/not-found.component';
import { GeoNavbarComponent } from '@app/core/components/geo-navbar/geo-navbar.component';
import { GeoNavigationContainerComponent } from '@app/core/components/geo-navigation-container/geo-navigation-container.component';
import { NavigationComponent } from '@app/core/components/navigation/navigation.component';
import { NavigationCollapsableItemComponent } from '@app/core/components/navigation/components/collapsable/collapsable.component';
import { MenuPreviewComponent } from '@app/core/components/navigation/components/menu-preview/menu-preview.component';
import { GeoLoaderComponent } from '@app/core/components/geo-loader/geo-loader.component';
import { NavigationAsideItemComponent } from '@app/core/components/navigation/components/aside/aside.component';
import { NavigationItemComponent } from '@app/core/components/navigation/components/item/item.component';
import { LogoComponent } from '@app/core/components/app-logo/logo.component';

@NgModule({
  declarations: [
    NotFoundComponent,
    GeoNavigationContainerComponent,
    GeoNavbarComponent,
    NavigationComponent,
    NavigationCollapsableItemComponent,
    MenuPreviewComponent,
    GeoLoaderComponent,
    NavigationAsideItemComponent,
    NavigationItemComponent,
    LogoComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    MatDividerModule,
    MatTooltipModule,
    TranslateModule,
  ],
  exports: [
    GeoNavigationContainerComponent,
    GeoNavbarComponent,
    NavigationComponent,
    GeoLoaderComponent,
    LogoComponent,
  ],
})
export class CoreModule {}
