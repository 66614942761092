import { createReducer, on } from '@ngrx/store';
import {
    getMeSuccess,
    getSubscriptionFailure,
    getSubscriptionSuccess,
    requestResendEmailForVerification,
    requestResendEmailForVerificationFailure,
    requestResendEmailForVerificationSuccess,
    requestResetPassword,
    requestResetPasswordFailure,
    requestResetPasswordSuccess,
    resetAuthStore,
    setTokenSuccess,
    signIn,
    signInFailure,
    signInSuccess,
    signOut,
    signUp,
    signUpFailure,
    signUpSuccess
} from '@app/shared/store/auth/auth.actions';
import { LoginUser } from "@app/shared/models/user/login.user.model";
import { User } from "@app/shared/models/user/user";
import { AuthToken } from "@app/core/auth-token";
import { PlanSubscription } from "@app/shared/models/user/plan-subscription";

export const authKey = 'auth';

export interface AuthState {
    verificationEmail: string,
    authUserData: LoginUser,
    meUser: User,
    error: string,
    isLoading: boolean,
    authToken: AuthToken,
    subscription: PlanSubscription,
    subscriptionLoaded: boolean,

}

export const initialState: AuthState = {
    verificationEmail: '',
    authUserData: {
        email: '',
        displayName: '',
        isVerified: false,
    },
    meUser: {} as User,
    error: '',
    isLoading: false,
    authToken: new AuthToken('', new Date()),
    subscription: {} as PlanSubscription,
    subscriptionLoaded: false,
};

export const authReducer = createReducer(
    initialState,
    on(signUp, signIn, requestResetPassword, requestResendEmailForVerification, (state) => ({
        ...state,
        error: initialState.error,
        isLoading: true,
    })),
    on(signUpSuccess, (state: AuthState, {email}) => ({
        ...state,
        verificationEmail: email,
        isLoading: false,
    })),
    on(signInSuccess, (state: AuthState, {authUserData}) => ({
        ...state,
        authUserData: authUserData,
        verificationEmail: authUserData.email,
        isLoading: false,
    })),
    on(requestResetPasswordSuccess, (state: AuthState, {email}) => ({
        ...state,
        verificationEmail: email,
        isLoading: false,
    })),

    on(requestResendEmailForVerificationSuccess, (state: AuthState) => ({
        ...state,
        isLoading: false,
    })),
    on(signUpFailure, signInFailure, requestResetPasswordFailure, requestResendEmailForVerificationFailure, (state: AuthState, {error}) => ({
        ...initialState,
        error: error,
        isLoading: false,
    })),
    on(resetAuthStore, signOut, () => ({
        ...initialState,
    })),
    on(setTokenSuccess, (state: AuthState, {token, expirationTime}) => ({
        ...state,
        authToken: new AuthToken(token, expirationTime),
    })),
    on(getMeSuccess, (state: AuthState, {user}) => ({
        ...state,
        meUser: user,
    })),
    on(getSubscriptionSuccess, (state: AuthState, {subscription: PlanSubscription}) => ({
        ...state,
        subscription: PlanSubscription,
        subscriptionLoaded: true,
    })),
    on(getSubscriptionFailure, (state: AuthState,) => ({
        ...state,
        subscription: initialState.subscription,
        subscriptionLoaded: false,
    })),
);
