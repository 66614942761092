import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as PlatformConfigActions from "@app/shared/store/platform-config/platform-config-actions";
import { switchMap } from "rxjs/operators";
import { of } from "rxjs";
import { ConfigService } from "@app/shared/services/config.service";

@Injectable()
export class PlatformConfigEffects {
  constructor(
    private actions$: Actions,
    private configService: ConfigService,
  ) {
  }

  setPlatformConfig = createEffect(() => this.actions$.pipe(
      ofType(PlatformConfigActions.setPlatformConfig),
      switchMap(() => this.configService.getPlatformConfig()),
      switchMap((config) => {
        return of(PlatformConfigActions.setPlatformConfigSuccess({
          platformConfig: {
            ...config,
            platformNameAr: config.platformNameAr,
            platformNameEn: config.platformNameEn,
            platform: config.platform
          }
        }))
      })
    ),
  );

}

