//"eq","in","contains","neq","gt","gte","le","lte"

export enum FilterOperator {
    EQUAL = "eq",
    IN = "in",
    LIKE = "contains",
    NOT_EQUAL = "neq",
    GREATER = "gt",
    GREATER_EQUAL = "gte",
    LESS = "le",
    LESS_EQUAL_THAN = "lte",
    SEARCH = "search",
    IS_NULL = "isNull",
    IS_NOT_NULL = "isNotNull",
}

// this is the example of a filter that this should support {"property":"emailVerified","operation":"eq","value":false}]
export interface FilterRequest {
    operation: FilterOperator;
    value: string[] | string | number;
    property: string;
}

