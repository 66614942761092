import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-status-asterisk',
  templateUrl: './status-asterisk.component.html',
  styleUrls: ['./status-asterisk.component.scss'],
})
export class StatusAsteriskComponent {
  @Input() isValid: boolean = false;
}
