import { ApplicationHttpClient } from "@app/helpers/custom-http-client";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { BrandCreationUsecase } from "@app/modules/onboarding/models/brandCreationInputForm";
import { HttpResult } from "@app/shared/models/http-result";
import { map } from "rxjs/operators";
import { Brand } from "@app/shared/models/brand";
import { GoogleConnectLocation } from "@app/shared/models/google-integration-location";
import { HttpParams } from "@angular/common/http";
import { Template } from "@app/shared/models/template";
import { Integration } from "@app/shared/models/integration";
import { FilterRequest } from '@app/shared/models/filter';
import { Competitor, FindCompetitorRequest, FindCompetitorResult } from "@app/shared/models/competitor";
import { LocationCreate } from "@app/modules/locations/models/create-location";
import { GeokingRule } from "@app/shared/models/geokingRule";


@Injectable()
export class BrandService {
    private brandsUrl = '/brands';

    constructor(private http: ApplicationHttpClient) {
    }

    public createBrand(brand: BrandCreationUsecase): Observable<Brand> {
        const createBrandURL: string = this.brandsUrl;
        return this.http.post<HttpResult>(createBrandURL, brand).pipe(
            map((result) => result.data)
        );
    }

    public manageLocations(brandId: string, locationIds: string[]): Observable<void> {
        const manageLocationsUrl: string = this.brandsUrl + '/' + brandId + '/manage-locations';
        return this.http.post(manageLocationsUrl, {locationIds: locationIds});
    }

    public getBrandLocations(brandId: string, active?: boolean): Observable<GoogleConnectLocation[]> {
        const locationSummeryUrl: string = this.brandsUrl + '/' + brandId + '/locations-summary';
        return this.http.get<HttpResult>(locationSummeryUrl, active !== undefined ? {
            params: new HttpParams().set('active', active)
        } : {}).pipe(map((it) => it.data));
    }

    public getBrands(): Observable<Brand[]> {
        const brandsUrl: string = this.brandsUrl;
        return this.http.get<HttpResult>(brandsUrl).pipe(map((it) => it.data));
    }

    public updateBrand(brand: Brand): Observable<Brand> {
        const updateBrandURL: string = this.brandsUrl;
        return this.http
            .put<HttpResult>(updateBrandURL + '/' + brand.id, brand)
            .pipe(map((result) => result.data));
    }

    public deleteBrand(brandId: string): Observable<boolean> {
        return this.http.delete<HttpResult>(this.brandsUrl + '/' + brandId)
            .pipe(map(() => true));
    }

    public getBrandTemplates(brandId: string, filters?: FilterRequest[]): Observable<Template[]> {
        let url: string = this.brandsUrl + '/' + brandId + '/templates';
        const params = new URLSearchParams();

        params.set('all_available', "true");

        if (filters?.length) {
            params.set('filter', JSON.stringify(filters));
        }

        url += '?' + params.toString();
        return this.http.get<HttpResult>(url).pipe(map((it) => it.data));
    }

    public getBrandIntegrations(brandId: string): Observable<Integration> {
        const integrationsUrl: string = this.brandsUrl + '/' + brandId + '/integrations';
        return this.http.get<HttpResult>(integrationsUrl).pipe(map((it) => it.data));
    }

    public setBrandsCompetitors(brandId: string, compIds: string[]): Observable<Integration> {
        const competitorsUrl: string = this.brandsUrl + '/' + brandId + '/competitors/select';
        return this.http.post<HttpResult>(competitorsUrl, {ids: compIds}).pipe(map((it) => {
            if (it.code == 400) {
                throw Error(it.message);
            } else {
                return it.data;
            }
        }));
    }

    public getBrandsCompetitors(brandId: string): Observable<Competitor[]> {
        const competitorsUrl: string = this.brandsUrl + '/' + brandId + '/competitors';
        return this.http.get<HttpResult>(competitorsUrl).pipe(map((it) => it.data));
    }

    removeCompetitor(brandId: string, id: string) {
        const competitorsUrl: string = this.brandsUrl + '/' + brandId + '/competitors/' + id;
        return this.http.delete<HttpResult>(competitorsUrl).pipe(map((it) => it.data));
    }

    createLocation(brandId: string, location: LocationCreate): Observable<void> {
        const locationCreate = `${this.brandsUrl}/${brandId}/locations`;
        return this.http
            .post<HttpResult>(locationCreate, location)
            .pipe(map((it) => it.data));
    }

    getRules(brandId: string, filters?: FilterRequest[]): Observable<GeokingRule[]> {
        let url: string = this.brandsUrl + '/' + brandId + '/rules';
        const params = new URLSearchParams();

        params.set('all_available', "true");

        if (filters?.length) {
            params.set('filter', JSON.stringify(filters));
        }

        url += '?' + params.toString();
        return this.http.get<HttpResult>(url).pipe(map((it) => it.data));

    }

    public createRule(brandId: string,rule: GeokingRule): Observable<GeokingRule> {
        const createURL: string = this.brandsUrl + '/' + brandId + '/rules';
        return this.http
            .post<HttpResult>(createURL, rule)
            .pipe(map((result) => result.data));
    }
    public findCompetitors(findCompetitor: FindCompetitorRequest): Observable<FindCompetitorResult[]> {
        const findCompetitorsUrl: string = `${this.brandsUrl}/${findCompetitor.brandId}/competitors/search`;
        return this.http.get<HttpResult>(findCompetitorsUrl,  {
            params: new HttpParams()
              .set('keywords', findCompetitor.keyWords)
              .set('radius', findCompetitor.radius)
              .set('latitude', findCompetitor.latitude)
              .set('longitude', findCompetitor.longitude)
        }).pipe(map((it) => it.data));
    }
}
