import { select, Store } from "@ngrx/store";
import { Injectable } from "@angular/core";
import {
  selectPaymentGateway,
  selectPlatformConfig,
  selectPlatformName
} from "@app/shared/store/platform-config/platform-config-selector";
import { setPlatformConfig } from "@app/shared/store/platform-config/platform-config-actions";
import { PlatformConfigState } from "@app/shared/store/platform-config/platform-config-reducer";
import { GeokPlatformConfig, GeokPlatformName, PaymentGateway } from "@app/configs/geokTheme";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class PlatformConfigFacade {
  constructor(private store: Store<PlatformConfigState>) {
  }


  selectPlatformName$: Observable<GeokPlatformName> = this.store.pipe(select(selectPlatformName));
  selectPlatformConfig$: Observable<GeokPlatformConfig> = this.store.pipe(select(selectPlatformConfig));
  selectPaymentGateway$: Observable<PaymentGateway> = this.store.pipe(select(selectPaymentGateway));
  setPlatformConfig(): void {
    this.store.dispatch(setPlatformConfig());
  }
}
