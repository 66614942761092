import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as HomeActions from "@app/store/home/home.actions";
import { mergeMap, of } from "rxjs";
import { ROUTER_NAVIGATED, RouterNavigatedAction } from "@ngrx/router-store";

@Injectable()
export class HomeEffects {
  constructor(
    private actions$: Actions,
  ) {
  }

  setSideNavbarVisibility$ = createEffect(() => this.actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      mergeMap((it: RouterNavigatedAction) => {
          if (it.payload.routerState.url.startsWith('/auth/') ||
            it.payload.routerState.url.startsWith('/' + 'not-found') ||
            it.payload.routerState.url.startsWith('/payment-gateway-handler')
          ) {
            return of(HomeActions.setSideBarVisibility({visible: false}));
          }
          return of(HomeActions.setSideBarVisibility({visible: true}))
        }
      )
    )
  );
  setIsOnBoarding$ = createEffect(() => this.actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      mergeMap((it: RouterNavigatedAction) => {
          if (it.payload.routerState.url.startsWith('/onboarding/')) {
            return of(HomeActions.setIsOnBoarding({isOnBoarding: true}));
          }
          return of(HomeActions.setIsOnBoarding({isOnBoarding: false}))
        }
      )
    )
  );
}
