import { Injectable } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { map, Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MediaWatcherService {
  // screen from _breakpoint.scss
  private _screens = {
    sm: '(min-width: 576px)',
    md: '(min-width: 768px)',
    lg: '(min-width: 992px)',
    xl: '(min-width: 1200px)',
    xxl: '(min-width: 1400px)',
  };

  private _onMediaChange: ReplaySubject<{
    matchingAliases: string[];
  }> = new ReplaySubject<{ matchingAliases: string[]; }>(
    1
  );

  /**
   * Constructor
   */
  constructor(private _breakpointObserver: BreakpointObserver) {
    this._breakpointObserver
      .observe(Object.values(this._screens))
      .pipe(
        map((state) => {
          // Prepare the observable values and set their defaults
          const matchingAliases: string[] = [];

          // Get the matching breakpoints and use them to fill the subject
          const matchingBreakpoints =
            Object.entries(state.breakpoints).filter(
              ([, matches]) => matches
            ) ?? [];
          for (const [query] of matchingBreakpoints) {
            // Find the alias of the matching query
            const matchingAlias = Object.entries(this._screens).find(
              ([, q]) => q === query
            )?.[0];

            // Add the matching query to the observable values
            if (matchingAlias) {
              matchingAliases.push(matchingAlias);
            }
          }

          // Execute the observable
          this._onMediaChange.next({
            matchingAliases,
          });
        })
      )
      .subscribe();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for _onMediaChange
   */
  get onMediaChange$(): Observable<{
    matchingAliases: string[];
  }> {
    return this._onMediaChange.asObservable();
  }
}
