import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'currencyNumberTranslate',
    pure: true,
})
export class CurrencyNumberTranslatePipe implements PipeTransform {
    transform(value: number | string | undefined, args?: { currency?: string }): string {
        const currentLanguage = moment.locale();
        const languagesNumberFormat: { [key: string]: string } = {
            ar: 'ar-EG',
            en: 'en-us',
        };

        const currencyCodeMapper: { [key: string]: string } = {
            'USD': '$',
            'AED': ' د.إ. ',
            'SAR': ' ر.س. '
        };
        if (value?.toString && typeof value !== 'object' && !Number.isNaN(parseFloat(value.toString()))) {
            let currencySymbol = '';
            if (args?.currency) {
                if (currentLanguage === 'en') {
                    currencySymbol = args?.currency;
                }
                if (currentLanguage === 'ar') {
                    currencySymbol = currencyCodeMapper?.[args.currency];
                }
            }

            const formattedNumber = new Intl.NumberFormat(languagesNumberFormat?.[currentLanguage]).format(parseFloat(value.toString()));

            return `${currencySymbol} ${formattedNumber}`;
        } else {
            return '';
        }
    }
}
