<app-geo-loader
  *ngIf="isLoadingTheme"
  [showBg]="true" [isWhite]="true"></app-geo-loader>
<header>
    <app-geo-navigation-container *ngIf="isSideBarVisible$ | async"
                                  [sideBarOpened]="sideBarOpened"
                                  [isOnBoarding]="(isOnBoarding$ | async)!"
    ></app-geo-navigation-container>

</header>

<main>
    <app-geo-navbar class="d-md-none"
                    *ngIf="isSideBarVisible$ | async"
                    (toggleSidebar)="toggleSidebar()"
    ></app-geo-navbar>
    <router-outlet></router-outlet>
    <!-- The main content of the app goes here, including the router outlet which displays the content for the current route -->
</main>

<footer>
    <!-- The app footer goes here -->
</footer>
<div *ngIf="sideBarOpened"
     [class.show]="sideBarOpened"
     class="navigation-overlay"
     (click)="toggleSidebar()"
     aria-hidden="true"
></div>

<div class="admin-login-strip"
  *ngIf="isLoggedInAsPlatformAdmin$ | async"><p>You are logged in as <span class="notranslate">{{ userEmail$ | async}}</span></p></div>
