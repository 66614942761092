import { Injectable } from '@angular/core';
import { ApplicationHttpClient } from '@app/helpers/custom-http-client';
import { Observable } from 'rxjs';
import { HttpResult } from '@app/shared/models/http-result';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ZohoApiService {
  private zohoUrl = '/zoho/jwt';
  constructor(private http: ApplicationHttpClient) {}

  public getZohoJwt(): Observable<string> {
    return this.http.get<HttpResult>(this.zohoUrl).pipe(map((it) => it.data));
  }
}
