import { createFeatureSelector, createSelector } from '@ngrx/store';
import { authKey, AuthState } from "@app/shared/store/auth/auth.reducer";
import { UserAccess, UserAccessRolesEnum } from "@app/shared/models/user/user-access";
import { InviteUserRolesEnum } from "@app/shared/models/user/invite-user-roles-enum";
import { AccessLevelEnum } from "@app/shared/models/user/access-level-enum";

export const selectAuthState = createFeatureSelector<AuthState>(
    authKey);

export const selectVerificationEmail = createSelector(
    selectAuthState,
    (it: AuthState) => it.verificationEmail
);

export const selectError = createSelector(
    selectAuthState,
    (it: AuthState) => it.error
);

export const selectIsLoading = createSelector(
    selectAuthState,
    (it: AuthState) => it.isLoading
);

export const selectIsLoggedIn = createSelector(
    selectAuthState,
    (it: AuthState) => it.authUserData != null && it.authUserData.isVerified
);
export const selectToken = createSelector(
    selectAuthState,
    (it: AuthState) => it.authToken
);
export const selectUser = createSelector(
    selectAuthState,
    (it: AuthState) => it.meUser
);
export const selectUserId = createSelector(
    selectAuthState,
    (it: AuthState) => it.meUser.id
);
export const selectUserCompanyId = createSelector(
    selectAuthState,
    (it: AuthState) => it.meUser.companyId
);

export const selectCompanySubscription = createSelector(
    selectAuthState,
    (it: AuthState) => it.subscription
);
export const selectIsSubscriptionLoaded = createSelector(
    selectAuthState,
    (it: AuthState) => it.subscriptionLoaded
);

export const selectUserRoles = createSelector(
    selectAuthState,
    (it: AuthState) => {
        const allRoles: UserAccessRolesEnum[] = [];
        if (!it.meUser.userAccesses) {
            return [];
        }
        it.meUser.userAccesses.forEach((access: UserAccess) => {
            if (access.role === InviteUserRolesEnum.ADMIN) {
                switch (access.accessLevel) {
                    case AccessLevelEnum.COMPANY:
                        allRoles.push(UserAccessRolesEnum.COMPANY_ADMIN);
                        break;
                    case AccessLevelEnum.BRAND:
                        allRoles.push(UserAccessRolesEnum.BRAND_ADMIN);
                        break;
                    case AccessLevelEnum.USER_ACCESS_GROUP:
                        allRoles.push(UserAccessRolesEnum.ACCESS_GROUP_ADMIN);
                        break;
                }
            } else if (access.role === InviteUserRolesEnum.SOCIAL_MANAGER) {
                switch (access.accessLevel) {
                    case AccessLevelEnum.BRAND:
                        allRoles.push(UserAccessRolesEnum.BRAND_SOCIAL_MANAGER);
                        break;
                    case AccessLevelEnum.USER_ACCESS_GROUP:
                        allRoles.push(UserAccessRolesEnum.ACCESS_GROUP_SOCIAL_MANAGER);
                        break;
                }
            }
        });
        return [...new Set(allRoles)];

    });

