<div class="dash-card">
  <div class="header">
    <h3 class="title">Health Check</h3>
    <mat-icon svgIcon="info-icon-2" matTooltip="Completeness average score of your google locations information"></mat-icon>
  </div>
  <div class="content">
    <svg
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      [attr.data-value]="completionScore"
    >
      <circle r="42.5" cx="50" cy="50" />
      <path
        class="meter"
        d="M7.5,50a42.5,42.5 0 1,0 85,0a42.5,42.5 0 1,0 -85,0"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <text
        x="50"
        y="50"
        text-anchor="middle"
        dominant-baseline="central"
        font-size="20"
        class="notranslate"
      >
        {{0 | numberTranslate}}%
    </text>
    </svg>
    <p>Total Completion Score</p>
  </div>
</div>
