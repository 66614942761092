import { Component, Input, } from '@angular/core';
import { NavigationItem } from '@app/core/components/navigation/navigation.types';

@Component({
    selector: 'app-navigation-item',
    templateUrl: './item.component.html',
    styleUrls: ['./item.component.scss'],
})
export class NavigationItemComponent {
    @Input() item!: NavigationItem;
    @Input() isChild = false;

    handleKeyDown(): void {
    // TODO: handle hey down
        console.log('keydown');
    }
}
