import { AuthPipe, emailVerified, loggedIn } from "@angular/fire/auth-guard";
import { forkJoin, Observable, of, pipe, switchMap } from "rxjs";
import { map } from "rxjs/operators";
import { User } from "firebase/auth";

const combineAuthPipes = (authPipes: AuthPipe[]) =>
  switchMap((t: Observable<User>) => forkJoin(authPipes.map(x => x(t))))


export const publicAuth = () =>
  pipe(
    map((t: User) => of(t)),
    combineAuthPipes(
      [loggedIn, emailVerified]),
    map(([isLoggedIn, isEmailVerified]) => {
      if (!isLoggedIn) {
        return true;
      }
      if (isLoggedIn && !isEmailVerified) {
        return true;
      }
      if (isLoggedIn && isEmailVerified) {
        return ['/onboarding'];
      } else {
        return true;
      }
    }),
  );

export const privateAuth = () => pipe(
  emailVerified,
  map(emailVerified => {
    if (emailVerified) {
      return true;
    } else {
      return ['auth/login'];
    }
  })
);

