<nav class="navbar">
  <div class="navbar-container">
    <app-logo  routerLink="/dashboard"></app-logo>
    <button
      class="menu-button"
      mat-icon-button
      (click)="toggleNavigation()"
    >
      <mat-icon>menu</mat-icon>
    </button>

  </div>
</nav>
