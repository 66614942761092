import { Component, Input, } from '@angular/core';
import { NavigationItem } from '@app/core/components/navigation/navigation.types';

@Component({
    selector: 'app-navigation-aside-item',
    templateUrl: './aside.component.html',
    styleUrls: ['./aside.component.scss'],
})
export class NavigationAsideItemComponent {
    @Input() item!: NavigationItem;
    @Input() active = false;
}


