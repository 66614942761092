import { Pipe, PipeTransform } from '@angular/core';
import { RuleStatus } from '@app/shared/models/geokingRule';

@Pipe({
  name: 'reviewStatusParser',
})
export class ReviewParserPipe implements PipeTransform {
  transform(rs: RuleStatus): string {
    switch (rs) {
      case RuleStatus.ACTIVE:
        return 'Active';
      case RuleStatus.EXPIRED:
        return 'Expired';
      case RuleStatus.INACTIVE:
        return 'Inactive';
      default:
        return '';
    }
  }
}
