import { Injectable, OnDestroy } from '@angular/core';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { combineLatest, Subscription, tap } from 'rxjs';
import { TranslateService } from "@ngx-translate/core";
import { PlatformConfigFacade } from "@app/shared/store/platform-config/platform-config-facade";

@Injectable()
export class CustomPageTitleStrategy
  extends TitleStrategy
  implements OnDestroy
{
  platformName = '';
  userSettingSubscription$ :Subscription = new Subscription();
  langChangeSubscription$ :Subscription = new Subscription();
  routeSnapshot!: RouterStateSnapshot;
  constructor(
    private readonly title: Title,
    private translateService: TranslateService,
    private platformConfigFacade: PlatformConfigFacade,
    ) {
      super();
      this.langChangeSubscription$ = combineLatest([
        this.translateService.onDefaultLangChange,
        this.platformConfigFacade.selectPlatformName$
      ]).pipe(
        tap(([lang, platformName]) => {
          this.platformName = lang.lang === 'ar' ? platformName.platformNameAr : platformName.platformNameEn;
          if(this.routeSnapshot) {
            this.updateTitle(this.routeSnapshot);
          }
        })
      ).subscribe();
      this.userSettingSubscription$ = this.platformConfigFacade.selectPlatformName$
      .pipe(
        tap((name) => {
          this.platformName = this.translateService.currentLang === 'ar' ? name.platformNameAr : name.platformNameEn;
          if(this.routeSnapshot) {
            this.updateTitle(this.routeSnapshot);
          }
        })
      )
      .subscribe();
  }

  override updateTitle(snapshot: RouterStateSnapshot): void {
    const pageTitle = this.buildTitle(snapshot);
    this.routeSnapshot = snapshot;
    const metaTitle = this.platformName && pageTitle ? `${pageTitle} - ${this.platformName}` : (pageTitle ? pageTitle: this.platformName);
    this.title.setTitle(metaTitle);
  }

  ngOnDestroy(): void {
    this.userSettingSubscription$.unsubscribe();
  }
}
