<div appDragDropFileUpload (fileDropped)="setImage($any($event))">
  <!-- IMAGE = FALSE - Show add image box -->
  <div class="input-image-placeholder" *ngIf="!image">
    <app-icon [iconName]="'add_photo_alternate'" width="70px" height="70px"></app-icon>

    <div class="my-25px text-center">
      <p class="mb-10px">Drop your image here to upload</p>

      @if (!disabled) {
        <app-button
          size="small"
          color="secondary-outlined-white"
          (clicked)="fileInput.click()"
        >
          Select Image
        </app-button>
      }
    </div>

    <input
      [disabled]="isLoading"
      hidden
      type="file"
      accept="image/jpeg, image/png"
      (change)="setImage($any($event).target.files)"
      #fileInput
    />

    <div class="requirements">
      <small>Photo must be at least 400 x 300 pixels in size</small>
      <small>Supports: JPG, JPEG, PNG</small>
    </div>
  </div>

  <!-- IMAGE = TRUE - Image preview -->
  <div class="image-preview" *ngIf="!!image">
    <div class="image-wrapper">
      <img [src]="image" alt="your photo" />
    </div>

    @if (!disabled) {
      <app-button
        class="delete-button"
        (clicked)="removePhoto()"
        size="small"
        color="black"
        [isIcon]="true"
      >
        <app-icon [iconName]="'delete-new'" width="16px" height="16px"></app-icon>
      </app-button>
    }
  </div>
</div>
