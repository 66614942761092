import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LocationCardStatus } from "@app/modules/locations/models/location-card-status";
import { CountryService } from '@app/shared/services/country.service';
import { GeoKLocation } from "@app/shared/models/geo-k-location";

@Component({
    selector: 'app-dialog-header',
    templateUrl: './dialog-header.component.html',
    styleUrls: ['./dialog-header.component.scss']
})
export class DialogHeaderComponent implements OnInit {
    @Input() title = '';
    @Input() subTitle = '';
    @Input() verificationStatus!: string;
    @Input() isSubtitleTranslatable = true;
    @Input() status!: LocationCardStatus;
    @Input() readOnly = false;
    @Input() icon!: string;
    @Output() closeDialog: EventEmitter<void> = new EventEmitter<void>();
    @Input() location?: GeoKLocation = {} as GeoKLocation;

    locationMode = false
    countryName = ''

    constructor(private countryService: CountryService) {

    }

    ngOnInit(): void {
        if (this.location?.id) {
            this.locationMode=true
            this.countryName = this.getCountryName(this.location.countryCode)
        }
    }

    getCountryName(id: string): string {
        return this.countryService.getCountryName(id);
    }

    closeDialogClicked(): void {
        if (!this.readOnly) {
            this.closeDialog.emit();
        }
    }

  protected readonly LocationCardStatus = LocationCardStatus;
}
