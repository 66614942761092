<div class="leave-dialog-panel-section">
  <!-- Dismiss button -->
  <div class="leave-dialog-panel-dismiss">
    <button mat-icon-button matDialogClose="cancelled">
      <mat-icon class="text-secondary" svgIcon="close-icon"></mat-icon>
    </button>
  </div>

  <div class="leave-dialog-panel-content">
    <img src="assets/unsaved.png" alt="">
    <div class="title">Unsaved changes</div>
    <div class="message">
      You haven't saved all of your changes. Save before you leave!
    </div>
  </div>

  <div class="leave-dialog-panel-action">
    <!-- Cancel -->
    <button class="secondary-button" matDialogClose="cancelled">Stay</button>
    <!-- Confirm -->
    <button class="primary-button" matDialogClose="leave">Leave</button>
  </div>
</div>
