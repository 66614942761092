import { Component, EventEmitter, Output } from '@angular/core';
import { AuthFacade } from '@app/shared/store/auth/auth.facade';

@Component({
  selector: 'app-geo-navbar',
  templateUrl: './geo-navbar.component.html',
  styleUrls: ['./geo-navbar.component.scss'],
})
export class GeoNavbarComponent {

  @Output() toggleSidebar : EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private authFacade: AuthFacade
  ) {
  }

  toggleNavigation(): void {
      this.toggleSidebar.emit();
  }

  signOut(): void {
    this.authFacade.signOut();
  }
}
