<label class="checkbox">
  <input type="checkbox" (change)="handleChange($event)" [checked]="active" [name]="uncheckedIcon">
  <div class="checkbox-visual">
   <div class="checkbox-unchecked">
     <mat-icon [svgIcon]="uncheckedIcon"></mat-icon>
     <span class="label">{{label}}</span>
   </div>
    <div class="checkbox-checked">
     <mat-icon [svgIcon]="checkedIcon"></mat-icon>
     <span class="label">{{label}}</span>
   </div>
  </div>
</label>
