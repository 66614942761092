<div class="switcher">
  <span
    [class.active]="selectedValue.value === valueOne.value"
    (click)="selectValueEmit(valueOne)">
  <p>
     {{valueOne.label}}
  </p>
  </span>
  <div class="separator"></div>
  <span
    [class.active]="selectedValue.value === valueTwo.value"
    (click)="selectValueEmit(valueTwo)">
   <p>
     {{valueTwo.label}}
   </p>
  </span>
</div>

