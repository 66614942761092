import { ApplicationHttpClient } from '@app/helpers/custom-http-client';
import { map, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpResult } from '@app/shared/models/http-result';
import {
  BillingCycleEnum,
  InvoiceLinkResult,
  PaymentResult,
  PlanTypeEnum,
} from '@app/shared/models/payment-result';

const SUBSCRIPTION_UPGRADE_REDIRECT_ROUTE =
  'subscription/payment-gateway-handler';
const SUBSCRIPTION_UPDATE_LICENCE_REDIRECT_ROUTE =
  'subscription/payment-gateway-handler-licence';
const SUBSCRIPTION_EXTEND_REDIRECT_ROUTE =
  'subscription/payment-extend-gateway-handler';

const PAYMENT_REDIRECT_ROUTE = 'onboarding/payment-gateway-handler';
@Injectable()
export class SubscriptionService {
  private subscriptionsURL = '/subscriptions';

  constructor(private http: ApplicationHttpClient) {}

  public startSubscription(
    quantity: number,
    billingCycle: BillingCycleEnum,
    selectedPlan: PlanTypeEnum,
    lang: string
  ): Observable<string> {
    const startSubscriptionURL = this.subscriptionsURL + '/start';
    const redirectUrl = `${window.location.origin}/${PAYMENT_REDIRECT_ROUTE}`;

    return this.http
      .post<HttpResult>(startSubscriptionURL, {
        quantity: quantity,
        billingCycle: billingCycle,
        selectedPlan: selectedPlan,
        redirectUrl: redirectUrl,
        lang: lang,
      })
      .pipe(map((it) => it.data));
  }

  public getPaymentProcessResult(params: string): Observable<PaymentResult> {
    const getPaymentProcessResultUrl =
      this.subscriptionsURL + `/process-payment${params}`;
    return this.http
      .get<HttpResult>(getPaymentProcessResultUrl)
      .pipe(map((it) => it.data));
  }

  public upgradePlan(lang: string): Observable<string> {
    const startSubscriptionURL = this.subscriptionsURL + '/start-upgrade';
    const redirectUrl = `${window.location.origin}/${SUBSCRIPTION_UPGRADE_REDIRECT_ROUTE}`;
    return this.http
      .post<HttpResult>(startSubscriptionURL, {
        redirectUrl: redirectUrl,
        lang: lang,
      })
      .pipe(map((it) => it.data));
  }

  public getPaymentUpgradeProcessResult(
    params: string
  ): Observable<PaymentResult> {
    const getPaymentProcessResultUrl =
      this.subscriptionsURL + `/process-upgrade-payment${params}`;
    return this.http
      .get<HttpResult>(getPaymentProcessResultUrl)
      .pipe(map((it) => it.data));
  }

  public startLicenceUpdate(lang: string, licence: number): Observable<string> {
    const startSubscriptionURL =
      this.subscriptionsURL + '/start-update-license';
    const redirectUrl = `${window.location.origin}/${SUBSCRIPTION_UPDATE_LICENCE_REDIRECT_ROUTE}`;
    return this.http
      .post<HttpResult>(startSubscriptionURL, {
        redirectUrl: redirectUrl,
        lang: lang,
        quantity: licence,
      })
      .pipe(map((it) => it.data));
  }

  public extendSubscription(lang: string): Observable<string> {
    const renewSubscriptionURL = this.subscriptionsURL + '/extend';
    const redirectUrl = `${window.location.origin}/${SUBSCRIPTION_EXTEND_REDIRECT_ROUTE}`;
    return this.http
      .post<HttpResult>(renewSubscriptionURL, {
        redirectUrl: redirectUrl,
        lang: lang,
      })
      .pipe(map((it) => it.data));
  }
  public getProcessUpdateLicensePayment(
    params: string
  ): Observable<PaymentResult> {
    const getUrl =
      this.subscriptionsURL + `/process-update-license-payment${params}`;
    return this.http.get<HttpResult>(getUrl).pipe(map((it) => it.data));
  }
  public getProcessExtendPayment(params: string): Observable<PaymentResult> {
    const getUrl = this.subscriptionsURL + `/process-extend-payment${params}`;
    return this.http.get<HttpResult>(getUrl).pipe(map((it) => it.data));
  }
  public getInvoiceLink(invoiceNumber: string): Observable<InvoiceLinkResult> {
    const getInvoiceLinkUrl =
      this.subscriptionsURL +
      `/` +
      invoiceNumber +
      `/generate-spl-invoice-link`;
    return this.http.get<HttpResult>(getInvoiceLinkUrl).pipe(map((it) => it));
  }
}
