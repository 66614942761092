import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Inject,
} from '@angular/core';
import {
  MatSnackBarRef,
  MAT_SNACK_BAR_DATA,
} from '@angular/material/snack-bar';

@Component({
  selector: 'app-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeoSnackBarComponent {
  /**
   * constructor
   */
  constructor(
    @Inject(MAT_SNACK_BAR_DATA)
    public data: {
      type: 'basic' | 'success' | 'error';
      title?: string;
      message?: string;
      dismissible?: boolean;
      button?: {
        label: string;
        action: () => void;
        icon?: string;
      }
    },
    public sbRef: MatSnackBarRef<GeoSnackBarComponent>
  ) {}

  handleButtonActionClick() {
    this.sbRef.dismiss();
    this.data?.button?.action();
  }
}
