<div class="google-location-container">
  <div class="d-flex flex-column flex-md-row justify-content-between align-items-between">
    <div class="d-flex flex-column align-items-start">
      <p class="subtitle">
        <ng-container *ngIf="showStep">
          <span class="bold"> Step 3:</span>
          <span class="notranslate">{{' '}}</span>
        </ng-container>
        <span>Activate your locations</span>
      </p>
      <p class="description">
        We recommend activating all your locations to optimize your local search visibility
      </p>
    </div>
    <div class="d-flex align-items-start justify-content-center">
      <app-geo-loader *ngIf="isLoading" [showBg]="true"
        [textToDisplay]="'Your locations are being synced'"></app-geo-loader>
      <button (click)="skipStep()" class="text-button skip">
        {{ cancelLabel }}
      </button>
      <button class="primary-button" (click)="continue()" [disabled]="selection.isEmpty() || isLoading">
        {{ activateLocationsLabel }}
      </button>
    </div>
  </div>
  <mat-form-field>
    <mat-label>Search</mat-label>
    <input matInput (keyup)="searchField($event)" placeholder="Search for location">
  </mat-form-field>
  <div class="table-wrapper">
    <div class="table-container">
      <table mat-table [dataSource]="dataSource">
        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox color="primary" (change)="$event ? toggleAllRows() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox color="primary" [disabled]="!row.owned" (click)="$event.stopPropagation()"
              (change)="row.owned ? ($event ? selection.toggle(row.id) : null) : null"
              [checked]="row.owned ? selection.isSelected(row.id) :  false">
            </mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="locations">
          <th mat-header-cell *matHeaderCellDef>
            <p class="header-title">
              <span>Locations</span>
            </p>
          </th>
          <td mat-cell *matCellDef="let element">
            <span class="notranslate">{{ element.name }}</span>
            <span class="notranslate">{{ element.storeCode }}</span>
            <span class="item-location notranslate">{{
              element.state + " , " + getCountryName(element.country) }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <span class="d-flex align-items-center">
              <ng-container *ngIf="!element.owned">
                <span>Owner role required</span>
              </ng-container>
              <ng-container *ngIf="element.owned">
                <ng-container *ngIf="element.verified">
                  <!-- TODO: change to to mat-icon -->
                  <img src="/assets/icons/verify-icon.svg" alt="" width="20" class="mx-2" height="20" />
                  <span class="d-none d-md-block">
                    Verified
                  </span>
                </ng-container>
                <ng-container *ngIf="!element.verified">
                  <img src="/assets/icons/close-icon.svg" alt="" width="20" class="d-md-none mx-2" />
                  <span class="d-none d-md-block">
                    Not Verified
                  </span>
                </ng-container>
              </ng-container>
            </span>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"
          [ngClass]="{ select: selection.hasValue() }"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"
          (click)="row.owned ? selection.toggle(row.id) :  null" [class.disabled]="row.owned === false"
          [ngClass]="{ select: selection.isSelected(row.id) }"></tr>
      </table>
    </div>

    <div class="footer-counter">
      <p *ngIf="selection.selected.length > 0">
        <span *ngIf="selection.selected.length === 1"><span class="notranslate">{{ selection.selected.length |
            numberTranslate }} </span>location selected</span>
        <span *ngIf="selection.selected.length > 1"><span class="notranslate">{{ selection.selected.length |
            numberTranslate }} </span>locations selected</span>
      </p>
    </div>
  </div>
</div>
