import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, switchMap, tap } from "rxjs/operators";
import * as AuthActions from '@app/shared/store/auth/auth.actions';
import { from, mergeMap, of, retry } from "rxjs";
import { Router } from "@angular/router";
import { FirebaseAuthService } from "@app/shared/services/firebase-auth.service";
import { ActionCallBack } from "@app/shared/models/action-callback.enum";
import { GeoSnackBarService } from "@app/shared/services/snack-bar/snack-bar.service";
import { UserService } from "@app/shared/services/user.service";
import { User } from "@app/shared/models/user/user";
import { AuthToken } from "@app/core/auth-token";
import { AuthFacade } from "@app/shared/store/auth/auth.facade";
import { ZohoService } from "@app/shared/services/zoho.service";

@Injectable()
export class AuthEffects {
    signUp$ = createEffect(() => this.actions$.pipe(
            ofType(AuthActions.signUp),
            exhaustMap((action) =>
                from(this.firebaseAuthService.signUpWithEmailAndPassword(action.email, action.password)).pipe(
                    map(() => {
                        return AuthActions.signUpSuccess({email: action.email});
                    }),
                    catchError((err) => {
                        const customError = this.firebaseAuthService.getFirebaseErrorMessage(err.code);
                        return of(AuthActions.signUpFailure({error: customError}));
                    }),
                ),
            ),
        ),
    );
    signUpSuccess$ = createEffect(() => this.actions$.pipe(
            ofType(AuthActions.signUpSuccess),
            tap(() => this.router.navigate(['/auth/account-verification'])),
        ), {dispatch: false}
    );

    signIn$ = createEffect(() => this.actions$.pipe(
            ofType(AuthActions.signIn),
            exhaustMap((action) =>
                from(this.firebaseAuthService.loginWithEmailAndPassword(action.email, action.password)).pipe(
                    map((result) => AuthActions.signInSuccess({authUserData: result})),
                    catchError((err) => {
                        const customError = this.firebaseAuthService.getFirebaseErrorMessage(err.code);
                        return of(AuthActions.signInFailure({error: customError}));
                    }),
                ),
            ),
        ),
    );
    signInSuccess$ = createEffect(() => this.actions$.pipe(
            ofType(AuthActions.signInSuccess),
            tap((user) => {
                if (user.authUserData.isVerified) {
                    this.authFacade.getMe();
                    this.router.navigate(['/onboarding']).then();
                } else {
                    this.router.navigate(['/auth/account-verification']).then();
                }
            }),
        ), {dispatch: false}
    );

    requestResetPassword$ = createEffect(() => this.actions$.pipe(
            ofType(AuthActions.requestResetPassword),
            exhaustMap((action) =>
                this.firebaseAuthService.requestResetPassword(action.email).pipe(
                    map(() => AuthActions.requestResetPasswordSuccess({email: action.email, callBack: action.callBack})),
                    catchError((err) => {
                        const customError = this.firebaseAuthService.getFirebaseErrorMessage(err.code);
                        return of(AuthActions.requestResetPasswordFailure({error: customError, callBack: action.callBack}));
                    }),
                ),
            ),
        ),
    );
    requestResetPasswordSuccess$ = createEffect(() => this.actions$.pipe(
            ofType(AuthActions.requestResetPasswordSuccess),
            tap((result) => {
                if (result.callBack == ActionCallBack.NAVIGATION) {
                    this.router.navigate(['/auth/reset-password-verification']);
                }
                if (result.callBack == ActionCallBack.SNACKBAR) {
                    this.geoSnackBarService.success({
                        title: 'Email resent',
                    });
                }
            }),
        ), {dispatch: false}
    );

    requestResetPasswordFailure$ = createEffect(() => this.actions$.pipe(
            ofType(AuthActions.requestResetPasswordFailure),
            tap((result) => {
                if (result.callBack == ActionCallBack.SNACKBAR) {
                    this.geoSnackBarService.error({
                        title: 'Failed to resend email',
                        message: result.error.toString(),
                    });
                }
            }),
        ), {dispatch: false}
    );
    requestResendEmailForVerification$ = createEffect(() => this.actions$.pipe(
            ofType(AuthActions.requestResendEmailForVerification),
            switchMap(() => this.firebaseAuthService.sendEmailVerification()),
            map(() => AuthActions.requestResendEmailForVerificationSuccess({}),
                catchError((err) => {
                    const customError = this.firebaseAuthService.getFirebaseErrorMessage(err.code);
                    return of(AuthActions.requestResendEmailForVerificationFailure({error: customError}));
                }),
            ),
        ),
    );
    requestResendEmailForVerificationSuccess$ = createEffect(() => this.actions$.pipe(
            ofType(AuthActions.requestResendEmailForVerificationSuccess),

        ), {dispatch: false}
    );

    requestResendEmailForVerificationFailure = createEffect(() => this.actions$.pipe(
            ofType(AuthActions.requestResendEmailForVerificationFailure),
            tap((error) => this.geoSnackBarService.error({
                title: 'Failed to resend email',
                message: error.error.toString(),
            })),
        ), {dispatch: false}
    );
    getMe$ = createEffect(() => this.actions$.pipe(
            ofType(AuthActions.getMe),
            mergeMap(() => this.userService.getMe().pipe(
                    map((user: User) => {
                        return AuthActions.getMeSuccess({user: user});
                    }),
                    catchError((error) => of(AuthActions.getMeFailure({error: error.message})))
                )
            ),
        ), {dispatch: true}
    );
    signOut$ = createEffect(() => this.actions$.pipe(
            ofType(AuthActions.signOut),
            tap(() => {
                this.firebaseAuthService.signOut().then(() => {
                        localStorage.clear();
                        this.zohoService.logout();
                        window.location.href = '/auth/login';
                    }
                ).catch(() => {
                    localStorage.clear();
                    this.zohoService.logout();
                    window.location.href = '/auth/login';
                });
            }),
        ), {dispatch: false}
    );
    setToken$ = createEffect(() => this.actions$.pipe(
            ofType(AuthActions.setToken),
            mergeMap((props) => this.firebaseAuthService.getToken(props.refresh).pipe(
                    map((it) => {
                        const authToken = new AuthToken(it.token, new Date(it.expirationTime));
                        if(authToken.isTokenExpired) {
                            return AuthActions.setToken({refresh: true});
                        }
                            return AuthActions.setTokenSuccess({token: it.token, expirationTime: new Date(it.expirationTime)});
                    }),
                )
            ),
        ), {dispatch: true}
    );

    getSubscription$ = createEffect(() => this.actions$.pipe(
        ofType(AuthActions.getSubscription),
        switchMap(() => this.userService.getMySubscription()),
        map((planSubscription) =>
             AuthActions.getSubscriptionSuccess({subscription: planSubscription})
        ),
        retry(1),
        catchError((error) => of(AuthActions.getSubscriptionFailure({error: error.message})))
    ));


    constructor(
        private actions$: Actions,
        private firebaseAuthService: FirebaseAuthService,
        private router: Router,
        private geoSnackBarService: GeoSnackBarService,
        private userService: UserService,
        private authFacade: AuthFacade,
        private zohoService: ZohoService,
    ) {
    }
}
