import { createAction, props } from "@ngrx/store";
import { Brand } from "@app/shared/models/brand";
import { BrandIntegrationTypes } from "@app/shared/models/integration";

export const fetchBrands = createAction(
  'fetch brands',

);
export const fetchBrandsSuccess = createAction(
  'fetch brands success',
  props<{  brands: Brand[] }>()
);

export const fetchBrandsFailure = createAction(
  'fetch brands Failure',
  props<{ error: string }>()
);
export const setSelectedBrand = createAction(
  'set selected brand',
  props<{ brand: Brand }>()
);
export const setSelectedBrandSocialIntegrationStatus = createAction(
  'set selected brand social integration flow status',
  props<{ data: {[key:string]: string  | boolean | null}, key: BrandIntegrationTypes }>()
);
export const resetBrandSocialIntegrationStatus = createAction(
  'reset selected brand social integration flow status'
);
