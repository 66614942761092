<div class="paging-range-actions">
  <button
    mat-icon-button
    type="button"
    class="paging-navigation-first"
    (click)="firstPage()"
    matTooltip="First Page"
    [matTooltipDisabled]="isPreviousPageDisabled"
    [matTooltipPosition]="'above'"
    [disabled]="isPreviousPageDisabled"
  >
    <svg class="paging-icon" viewBox="0 0 24 24" focusable="false" aria-hidden="true">
      <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"/>
    </svg>
  </button>
  <button
    mat-icon-button
    type="button"
    class="paging-navigation-previous"
    (click)="previousPage()"
    matTooltip="Previous Page"
    [matTooltipDisabled]="isPreviousPageDisabled"
    [matTooltipPosition]="'above'"
    [disabled]="isPreviousPageDisabled"
  >
    <!-- prettier-ignore -->
    <svg class="paging-icon" viewBox="0 0 24 24" focusable="false" aria-hidden="true">
      <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"/>
    </svg>
  </button>

  <span class="paging-range-label">
    <span>Page</span>
    <span class="notranslate"> {{pageIndex | numberTranslate}} </span>
    <span>of</span>
    <span class="notranslate"> {{ totalNumberOfPages | numberTranslate }}</span>
  </span>

  <button
    mat-icon-button
    type="button"
    class="paging-navigation-next"
    (click)="nextPage()"
    matTooltip="Next page"
    [matTooltipDisabled]="isNextPageDisabled"
    [matTooltipPosition]="'above'"
    [disabled]="isNextPageDisabled"
  >
    <!-- prettier-ignore -->
    <svg class="paging-icon" viewBox="0 0 24 24" focusable="false" aria-hidden="true">
      <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"/>
    </svg>
  </button>
  <button
    mat-icon-button
    type="button"
    class="paging-navigation-last"
    (click)="lastPage()"
    matTooltip="Last Page"
    [matTooltipDisabled]="isNextPageDisabled"
    [matTooltipPosition]="'above'"
    [disabled]="isNextPageDisabled"
  >
    <!-- prettier-ignore -->
    <svg class="paging-icon" viewBox="0 0 24 24" focusable="false" aria-hidden="true">
      <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"/>
    </svg>
  </button>
</div>
