import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'upperNumber'
})
export class UpperNumberPipe implements PipeTransform {

  transform(value: number, args: { precision: number }={precision: 2}): number {
    const precisionMultDivis = Math.pow(10, args.precision);
    return Math.round((value + Number.EPSILON) * precisionMultDivis) / precisionMultDivis;
  }

}
