<div class="navigation-item-wrapper">
    <a class="navigation-item" *ngIf="!!item.link"
       [ngClass]="{
       'active': item.active ,
       'child': isChild,
       'disabled ': item.disabled}"
       [routerLink]="[item.link]"
       [routerLinkActiveOptions]="{exact:  true}"
       [routerLinkActive]="'active'"
       [matTooltip]="item.tooltip || ''"
       [attr.tx-string-group]="item.id"
    >{{item.title}}
    </a>
    <div *ngIf="item.onClick" (click)="item.onClick()" (keydown)="handleKeyDown()" role="button" tabindex="0">
        <a class="navigation-item"
           [class.disabled]="item.disabled"
           [matTooltip]="item.tooltip || ''"
           [attr.tx-string-group]="item.id"
        >{{item.title}}
        </a>
    </div>
</div>

