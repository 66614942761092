import { ApplicationHttpClient } from '@app/helpers/custom-http-client';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { UserUpdateInfo } from '@app/shared/models/user/user.update';
import { User } from '@app/shared/models/user/user';
import {
  HttpResult,
  HttpResultWithPagination,
} from '@app/shared/models/http-result';
import { map } from 'rxjs/operators';
import { Company, CompanyUser } from '@app/shared/models/company';
import { Brand } from '@app/shared/models/brand';
import { OnboardingDirection } from '@app/shared/models/user/onboarding.direction.model';
import { GeoKLocation } from '@app/shared/models/geo-k-location';
import { Review } from '@app/modules/reviews/models/review';
import { ReviewStats } from '@app/shared/models/review-stats';
import { UserAccess } from '@app/shared/models/user/user-access';
import { Pagination, PagingRequest } from '@app/shared/models/paging.class';
import { FilterRequest } from '@app/shared/models/filter';
import { LocationStats } from '@app/shared/models/location-stats';
import { PlanSubscription } from '@app/shared/models/user/plan-subscription';
import { IPostResponse } from '@app/modules/social-posting/models/post.interface';

@Injectable()
export class UserService {
  private usersURL = '/users';
  reviewsDashboard$: BehaviorSubject<ReviewStats> =
    new BehaviorSubject<ReviewStats>({} as ReviewStats);

  constructor(private http: ApplicationHttpClient) {}

  public linkUser(email: string): Observable<void> {
    const postUserURL = this.usersURL + '/link';
    return this.http.post(postUserURL, { email: email });
  }

  public updateUser(
    userId: string,
    userUpdateInfo: UserUpdateInfo,
  ): Observable<void> {
    const updateUserURL = this.usersURL + '/me';
    return this.http.put(updateUserURL, userUpdateInfo);
  }

  public getMe(): Observable<User> {
    const getMeUrl = this.usersURL + '/me';
    return this.http.get<HttpResult>(getMeUrl).pipe(map((it) => it.data));
  }

  public getMyCompany(): Observable<Company> {
    const getMyCompanyUrl = this.usersURL + '/me/company';
    return this.http
      .get<HttpResult>(getMyCompanyUrl)
      .pipe(map((it) => it.data));
  }

  public getMyBrands(): Observable<Brand[]> {
    const getMyBrandsUrl = this.usersURL + '/me/brands';
    return this.http.get<HttpResult>(getMyBrandsUrl).pipe(map((it) => it.data));
  }

  public getMySubscription(): Observable<PlanSubscription> {
    const getMySubscriptionUrl = this.usersURL + '/me/subscription';
    return this.http
      .get<HttpResult>(getMySubscriptionUrl)
      .pipe(map((it) => it.data));
  }

  public getOnboardingDirection(): Observable<OnboardingDirection> {
    const getOnboardingDirectionUrl =
      this.usersURL + '/me/onboarding-directions';
    return this.http.get<HttpResult>(getOnboardingDirectionUrl).pipe(
      map((it: HttpResult) => {
        return {
          ...(it.data as NonNullable<object>),
          message: it.message,
        } as OnboardingDirection;
      }),
    );
  }

  public getMyLocations(
    pagingRequest: PagingRequest,
    filters?: FilterRequest[],
  ): Observable<{
    items: GeoKLocation[];
    pagination: Pagination;
  }> {
    const getMyLocationsUrl = this.usersURL + '/me/locations';
    let query = `?page=${pagingRequest.page}&limit=${pagingRequest.limit}`;

    if (!!filters && filters.length !== 0) {
      const tagQuery = filters.find((filter) => filter.property == 'tagId');

      if (tagQuery) {
        query += `&tags=${JSON.stringify(tagQuery.value)}`;
      }

      filters = filters.filter((filter) => filter.property !== 'tagId');

      if (!!filters && filters.length != 0) {
        query += `&filter=${JSON.stringify(filters)}`;
      }
    }

    return this.http
      .get<HttpResultWithPagination>(getMyLocationsUrl + query)
      .pipe(
        map((it) => {
          return {
            items: it.data,
            pagination: {
              pageIndex: it.page,
              totalCount: it.totalCount,
            },
          };
        }),
      );
  }

  public fetchMyReviewsDashboard(): Observable<ReviewStats> {
    return this.reviewsDashboard$.asObservable();
  }

  public getMyReviewDashboard(): Observable<ReviewStats> {
    const myReviewsUrl = this.usersURL + '/me/locations/reviews-stats';
    return this.http.get<HttpResult>(myReviewsUrl).pipe(
      map((it) => {
        this.reviewsDashboard$.next(it.data);
        return it.data;
      }),
    );
  }

  public getMyReviews(
    pagingRequest: PagingRequest,
    filters?: FilterRequest[],
    action?: string,
  ): Observable<{
    items: Review[];
    pagination: Pagination;
  }> {
    const getMyReviewsUrl = this.usersURL + '/me/locations/reviews';

    let query = `?page=${pagingRequest.page}&limit=${pagingRequest.limit}`;

    if (action) {
      query += `&action=${action}`;
    }

    if (!!filters && filters.length != 0) {
      query += `&filter=${JSON.stringify(filters)}`;
    }

    return this.http
      .get<HttpResultWithPagination>(getMyReviewsUrl + query)
      .pipe(
        map((it) => {
          return {
            items: it.data,
            pagination: {
              pageIndex: it.page,
              totalCount: it.totalCount,
            } as Pagination,
          };
        }),
      );
  }

  getUserAccesses(): Observable<UserAccess[]> {
    const getUserAccessesUrl = this.usersURL + '/me/user-accesses';
    return this.http
      .get<HttpResult>(getUserAccessesUrl)
      .pipe(map((it) => it.data));
  }

  public getUser(id: string): Observable<User> {
    const getUserUrl = this.usersURL + '/' + id;
    return this.http.get<HttpResult>(getUserUrl).pipe(map((it) => it.data));
  }

  public updateUserAccess(user: CompanyUser): Observable<CompanyUser> {
    const updateUserWithAccessUrl =
      this.usersURL + '/' + user.id + '/with-access';
    return this.http
      .put<HttpResult>(updateUserWithAccessUrl, user)
      .pipe(map((it) => it.data));
  }

  getMyPosts(filters?: FilterRequest[]): Observable<IPostResponse[]> {
    let query = '';
    if (!!filters && filters.length !== 0) {
      query += `?filter=${JSON.stringify(filters)}`;
    }

    return this.http
      .get<HttpResult>(this.usersURL + '/me/posts' + query)
      .pipe(map((it) => it.data));
  }

  public getLocationStatus(): Observable<LocationStats> {
    const locationStatusUrl = this.usersURL + '/me/locations/stats';
    return this.http
      .get<HttpResult>(locationStatusUrl)
      .pipe(map((it) => it.data));
  }
  public deleteUser(userId: string): Observable<boolean> {
    return this.http
      .delete<HttpResult>(this.usersURL + '/' + userId)
      .pipe(map(() => true));
  }
  public changeActivationStatus(
    userId: string,
    action: string,
  ): Observable<boolean> {
    const activationUrl = this.usersURL + '/' + userId + '/status/' + action;
    return this.http
      .put<HttpResult>(activationUrl, { id: userId, action: action })
      .pipe(map(() => true));
  }
}
