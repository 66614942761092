import { createReducer, on } from "@ngrx/store";
import { GeokPlatformConfig, PaymentGateway } from "@app/configs/geokTheme";
import { setPlatformConfigSuccess } from "@app/shared/store/platform-config/platform-config-actions";

export const platformConfigKey = 'platformConfig';

export interface PlatformConfigState {
  platformConfig: GeokPlatformConfig;
}

export const initialState: PlatformConfigState = {
  platformConfig: {
    paymentGateway: PaymentGateway.UPG,
    platformNameAr: '',
    platformNameEn: '',
    platform: ''
  }
}
export const PlatformConfigReducer = createReducer(
  initialState,
  on(setPlatformConfigSuccess, (state, {platformConfig}) => ({
    ...state,
    platformConfig: platformConfig,
  })),
);
