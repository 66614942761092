import { ApplicationHttpClient } from "@app/helpers/custom-http-client";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpResult, HttpResultWithPagination } from "@app/shared/models/http-result";
import { map } from "rxjs/operators";
import { Payment } from "@app/shared/models/payment-result";


@Injectable()
export class PaymentService {
  private paymentsUrl = '/payments';

  constructor(private http: ApplicationHttpClient) {
  }

  public getAll(): Observable<{
    items: Payment[],
  }> {
    return this.http.get<HttpResultWithPagination>(this.paymentsUrl).pipe(
      map((result) => {
        return {
          items: result.data,
          pagination: {
            pageIndex: result.page,
            totalCount: result.totalCount
          }
        }
      })
    );
  }

  public getPayment(paymentId: string): Observable<Payment> {
    const getPaymentURL: string = this.paymentsUrl + '/' + paymentId;
    return this.http.get<HttpResult>(getPaymentURL).pipe(
      map((result) => result.data)
    );
  }
}
