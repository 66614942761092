export interface GeokingRule {
    id: string;
    name: string;
    brandId: string;
    // conditions
    locationIds: string[];
    rating: {
      startRating: 0;
      endRating: 0;
    };
    // TODO: create enums
    replyStatus: 'ONLY_HAS_REPLY' | 'ONLY_HAS_NO_REPLY' | 'DONT_CARE_IF_HAS_REPLY';
    contentStatus: 'ONLY_HAS_CONTENT' | 'ONLY_HAS_NO_CONTENT' | 'DONT_CARE_IF_HAS_CONTENT';
    order: number;
    // status
    status: RuleStatus;
    dateRange: {
      endDate: {
        day: number;
        month: number;
        year: number;
      };
      startDate: {
        day: number;
        month: number;
        year: number;
      };
    };
    days: string[];
    flag: true;
    autoReply: true;
    replyTemplate: string;
  }
  
  export interface ExtendedRule extends GeokingRule {
      activeState: RuleStatus,
  }
  
  export enum RuleStatus {
      ACTIVE = 'ACTIVE',
      INACTIVE = 'INACTIVE',
      EXPIRED = 'EXPIRED',
  }
