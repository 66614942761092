import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {SelectBrandDialogComponent} from "@app/shared/components/select-brand/select-brand-dialog.component";
import {Observable, Subscription, tap} from "rxjs";
import {MatDialog} from "@angular/material/dialog";
import {BrandsFacade} from "@app/shared/store/brands/brands-facade";
import {Brand} from "@app/shared/models/brand";
import {shareObservable} from "@app/helpers/rxjs-helper";

@Component({
  selector: 'app-brand-selection',
  templateUrl: './brand-selection.component.html',
  styleUrls: ['./brand-selection.component.scss']
})
export class BrandSelectionComponent implements OnInit, OnDestroy {
  @Output() selectBrand: EventEmitter<Brand> = new EventEmitter<Brand>();
  selectedBrand: Observable<Brand> = new Observable<Brand>();
  brands$: Observable<Brand[]> = new Observable<Brand[]>();
  selectBrandSubscription$: Subscription = new Subscription();

  constructor(private matDialog: MatDialog,
              private brandsFacade: BrandsFacade
  ) {
  }

  ngOnInit() {
    this.brandsFacade.fetchBrands();
    this.brands$ = this.brandsFacade.selectBrands$.pipe(shareObservable());
    this.selectedBrand = this.brandsFacade.selectSelectedBrand$.pipe(shareObservable());
  }

  selectBrandClick() {
    this.selectBrandSubscription$ = this.matDialog
      .open(SelectBrandDialogComponent, {
        width: '556px',
        panelClass: 'geo-md-dialog',
      })
      .afterClosed()
      .pipe(
        tap((brand) => {
          if (brand) {
            this.brandsFacade.selectBrand(brand);
          }
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.selectBrandSubscription$.unsubscribe();
  }
}
