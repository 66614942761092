import { MonoTypeOperatorFunction, ReplaySubject, share } from 'rxjs';

/**
 * Way to use it:
 * const observable$ = x.pipe(shareObservable())
 * **/

export const shareObservable = <T>(): MonoTypeOperatorFunction<T> =>
    share({
        connector: () => new ReplaySubject(1),
        resetOnError: false,
        resetOnComplete: false,
        resetOnRefCountZero: true
    });
