import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-input-icon-toggle',
  templateUrl: './input-icon-toggle.component.html',
  styleUrl: './input-icon-toggle.component.scss',
})
export class InputIconToggleComponent {
  @Input() active = false;
  @Input() checkedIcon = '';
  @Input() uncheckedIcon = '';
  @Input() label = '';
  @Output() changeEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  handleChange($event: Event) {
    const checkbox = $event.target as HTMLInputElement;
    const isChecked = checkbox.checked;
    this.active = isChecked;
    this.changeEvent.emit(isChecked);
  }
}
