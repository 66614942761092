import { Brand } from "@app/shared/models/brand";
import { createReducer, on } from "@ngrx/store";
import {
  fetchBrandsSuccess,
  resetBrandSocialIntegrationStatus,
  setSelectedBrand,
  setSelectedBrandSocialIntegrationStatus
} from "@app/shared/store/brands/brands-actions";
import { BrandIntegrationStatues } from "@app/shared/models/integration";

export const brandsKey = 'brands';

export interface BrandsState {
  brands: Brand[];
  selectedBrand: Brand;
  socialIntegrationStatuses: BrandIntegrationStatues;
}

export const initialState: BrandsState = {
  brands: [],
  selectedBrand: {} as Brand,
  socialIntegrationStatuses: {},
};
export const BrandsReducer = createReducer(
  initialState,
  on(
    fetchBrandsSuccess,
    (state: BrandsState, {brands}) => ({
      ...state,
      brands: brands,
    })
  ),
  on(
    setSelectedBrand,
    (state: BrandsState, {brand}) => ({
      ...state,
      selectedBrand: brand,
    })
  ),
  on(
    setSelectedBrandSocialIntegrationStatus,
    (state: BrandsState, {data, key}) => ({
      ...state,
      socialIntegrationStatuses: {
        [key]: data,
      },
    })
  ),
  on(
    resetBrandSocialIntegrationStatus,
    (state: BrandsState) => {
      return {
        ...state,
        socialIntegrationStatuses: initialState.socialIntegrationStatuses,
      }
    }
  ),
);
