import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { GoogleConnectLocation } from '@app/shared/models/google-integration-location';
import { CountryService } from '@app/shared/services/country.service';
import { Brand } from '@app/shared/models/brand';

@Component({
  selector: 'app-google-locations',
  templateUrl: './google-locations.component.html',
  styleUrls: ['./google-locations.component.scss'],
})
export class GoogleLocationsComponent implements OnInit {
  constructor(private countryService: CountryService) {}

  @Input() activateLocationsLabel = 'Continue';
  @Input() cancelLabel = 'Skip for now';
  @Input() showStep = false;
  @Input() locations: GoogleConnectLocation[] = [];
  @Input() isLoading = false;
  @Input() brand?: Brand;

  @Output() activateLocations: EventEmitter<string[]> = new EventEmitter<
    string[]
  >();
  @Output() skipActivation: EventEmitter<void> = new EventEmitter<void>();

  displayedColumns: string[] = ['select', 'locations', 'status'];

  ngOnInit() {
    this.dataSource.data = this.locations;
  }

  dataSource: MatTableDataSource<GoogleConnectLocation> =
    new MatTableDataSource<GoogleConnectLocation>([]);

  selection: SelectionModel<string> = new SelectionModel<string>(true, []);
  locationsSelectedCount = 0;

  isAllSelected(): boolean {
    return this.dataSource.data.every((data) =>
      this.selection.selected.includes(data.id),
    );
  }

  getCountryName(countryCode: string): string {
    return this.countryService.getCountryName(countryCode) ?? countryCode;
  }
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    const list = this.dataSource.data.map((x) => x.id);
    if (list) {
      this.selection.select(...list);
    }
  }
  skipStep(): void {
    this.skipActivation.emit();
  }

  continue(): void {
    const locationIds = this.selection.selected;
    this.activateLocations.emit(locationIds);
  }

  searchField(event: KeyboardEvent) {
    this.dataSource.data = this.locations;
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    // Collect filtered data
    const filteredData = this.dataSource.filteredData;
    this.dataSource.data = [...filteredData];
  }
}
