<div class="confirmation-dialog-panel-section">
  <!-- Dismiss button -->
  <ng-container *ngIf="data.dismissible">
    <div class="confirmation-dialog-panel-dismiss">
      <button mat-icon-button matDialogClose="cancelled">
        <mat-icon class="text-secondary" svgIcon="close-icon"></mat-icon>
      </button>
    </div>
  </ng-container>

  <!-- Content -->
  <div class="confirmation-dialog-panel-content">
    <img src="/assets/delete-confirmation.png" alt="">
    <ng-container *ngIf="data.title || data.message">
      <!-- Title -->
      <ng-container *ngIf="data.title">
        <div class="title" [innerHTML]="data.title"></div>
      </ng-container>

      <!-- Message -->
      <ng-container *ngIf="data.message">
        <div class="message" [innerHTML]="data.message"></div>
      </ng-container>
    </ng-container>
  </div>

  <!-- Actions -->
  <ng-container
    *ngIf="data.actions?.confirm?.show || data.actions?.cancel?.show"
  >
    <div class="confirmation-dialog-panel-action">
      <!-- Cancel -->
      <ng-container *ngIf="data.actions?.cancel?.show">
        <button class="secondary-button" matDialogClose="cancelled">
          {{ data.actions?.cancel?.label }}
        </button>
      </ng-container>

      <!-- Confirm -->
      <ng-container *ngIf="data.actions?.confirm?.show">
        <button
          class="primary-outlined-button"
          [ngClass]="data.actions?.confirm?.class"
          matDialogClose="confirmed"
        >
          {{ data.actions?.confirm?.label }}
        </button>
      </ng-container>
    </div>
  </ng-container>
</div>
