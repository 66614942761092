import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ErrorService {
    // R&D: check if providing json file for these errors are better way to handle them, having one point of error handling (backend) seems to be easier way to handle it
    private list: { [key: string]: string } = {
        duplicate_tag_name: 'Tag with the same name already exists',
        duplicate_brand_name: 'Brand with the same name already exists',
        only_one_company_allowed: "Only one company is allowed per user.",
        access_denied: "Access denied. You do not have the required permissions.",
        user_already_exist_with_given_email: "A user already exists with the provided email address.",
        at_least_one_competitor_should_be_selected: "At least one competitor should be selected.",
        quota_exceeded: "Quota exceeded. You have reached the maximum limit for this action.",
        duplicate_category_arabic_name: "Duplicate Arabic category name. Please choose a unique name.",
        duplicate_category_english_name: "Duplicate English category name. Please choose a unique name.",
        industry_category_not_found: "Industry category not found. Please check the provided category.",
        duplicate_industry_arabic_name: "Duplicate Arabic industry name. Please choose a unique name.",
        duplicate_industry_english_name: "Duplicate English industry name. Please choose a unique name.",
        duplicate_access_group_name: "Duplicate access group name. Please choose a unique name.",
        invalid_tag_id: "Invalid tag ID. Please provide a valid tag ID.",
        invalid_location_id: "Invalid location ID. Please provide a valid location ID.",
        location_count_exceed_subscription: "Your subscription does not allow for this amount of active locations only. Please upgrade your subscription.",
        no_active_subscription: "No active subscription found. Please subscribe to access this feature.",
        plan_should_be_essential_or_expert: "Plan should be either Essential or Expert for this action.",
        missing_using_detail: "Missing user details. Please provide all required user information.",
        no_plan_found_for_country: "No plan found for the specified country. Please choose a different country or contact support.",
        insufficient_license_count: "Insufficient license count. Please purchase additional licenses to proceed.",
        invalid_payment: "Invalid payment. Please check your payment details and try again.",
        payment_already_processed: "Payment already processed. Your payment for this transaction has already been completed.",
        cannot_delete_connected_brand: "You cannot delete this brand because it is connected to the Google platform.",
        missing_cr_number: "CRNumber is missing to process your request.",
        missing_phone_number: "Phone number is missing to process your request.",
        invalid_cr_or_phone_number: "Your CR Number or Phone number is invalid.",
        user_not_found: "User not found to proceed.",
        location_status_invalid: "The given location status is invalid to proceed your request.",
        file_is_invalid: "The given file is invalid.",
        file_not_image: "The given file is not an image.",
        could_not_process_file: "Could not process your file, please try again.",
        could_not_fetch_file: "Could not fetch your file, please try again.",
        model_validation_error: "Fail to parse the information, please contact admins.",
        unauthorized: "You do not have permission to proceed with your action.",
        fail_to_parse_query: "Fail to parse the information, please contact admins.",
        general_not_found: "The information you are looking for no longer exist.",
    };

    getErrorMessage(value: string): string {
        return this.list[value] ?? 'Error while processing your request, please try again.';
    }
}