<div id="transifex-languagePicker" class="transifex-languagePicker" *ngIf="languages.length > 0">

    <div class="txlive-langselector-toggle notranslate" id="tx-live-lang-toggle"
         aria-haspopup="listbox"
         role="button"
         [attr.aria-expanded]="isLanguagePickerOpen"
         (click)="onToggleLanguagePicker()">
        <span class="txlive-langselector-current" id="tx-live-lang-current" aria-live="polite" aria-atomic="true">{{language.name}}</span>
        <span class="txlive-langselector-marker"></span>
    </div>
    <ul id="tx-live-lang-picker"
        role="listbox"
        [attr.aria-hidden]="!isLanguagePickerOpen"
        aria-labelledby="tx-live-lang-toggle"
        class="txlive-langselector-list notranslate" [ngClass]="{'txlive-langselector-list-opened': isLanguagePickerOpen}">
      <li *ngFor="let item of languages" (click)="onLanguageChange(item.code)"
        role="option" tabindex="0">{{item.name}}</li>
    </ul>
</div>
