import { select, Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import {
  getMe,
  getSubscription,
  requestResendEmailForVerification,
  requestResetPassword,
  resetAuthStore,
  setToken,
  signIn,
  signOut,
  signUp,
} from '@app/shared/store/auth/auth.actions';
import { AuthState } from '@app/shared/store/auth/auth.reducer';
import {
  selectCompanySubscription,
  selectError,
  selectIsLoading,
  selectIsSubscriptionLoaded,
  selectToken,
  selectUser,
  selectUserCompanyId,
  selectUserId,
  selectUserRoles,
  selectVerificationEmail,
} from '@app/shared/store/auth/auth.selector';
import { ActionCallBack } from '@app/shared/models/action-callback.enum';

@Injectable({
  providedIn: 'root',
})
export class AuthFacade {
  constructor(private store: Store<AuthState>) {}

  selectVerificationEmail$ = this.store.pipe(select(selectVerificationEmail));
  selectError$ = this.store.pipe(select(selectError));
  selectIsLoading$ = this.store.pipe(select(selectIsLoading));
  selectToken$ = this.store.pipe(select(selectToken));
  selectUser$ = this.store.pipe(select(selectUser));
  selectUserId$ = this.store.pipe(select(selectUserId));
  selectUserCompanyId$ = this.store.pipe(select(selectUserCompanyId));
  selectUserRoles= this.store.pipe(select(selectUserRoles));
  selectCompanySubscription$= this.store.pipe(select(selectCompanySubscription));
  selectIsSubscriptionLoaded$ =  this.store.pipe(select(selectIsSubscriptionLoaded));
  
  resetAuthStore(): void {
    this.store.dispatch(resetAuthStore());
  }

  signUp(email: string, password: string): void {
    this.store.dispatch(signUp({ email: email, password: password }));
  }

  signIn(email: string, password: string): void {
    this.store.dispatch(signIn({ email: email, password: password }));
  }

  resendVerificationEmail(): void {
    this.store.dispatch(requestResendEmailForVerification());
  }

  signOut(): void {
    this.store.dispatch(signOut());
  }

  requestResetPassword(email: string, actionCallBack: ActionCallBack) {
    this.store.dispatch(
      requestResetPassword({ email: email, callBack: actionCallBack })
    );
  }

  setToken(refresh: boolean): void {
    this.store.dispatch(setToken({ refresh: refresh }));
  }

  getMe() {
    this.store.dispatch(getMe());
  }

  getSubscription() {
    this.store.dispatch(getSubscription());
  }
}
