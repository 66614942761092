import { Injectable } from '@angular/core';
import { ApplicationHttpClient } from '@app/helpers/custom-http-client';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AssetsService {
  private assetsUrl = '/assets';

  constructor(private http: ApplicationHttpClient) {}

  uploadFile(file: File): Observable<string> {
    const formData = new FormData();
    formData.append('file', file);

    return this.http
      .post<{ url: string }>(this.assetsUrl, formData)
      .pipe(map((result) => result.url));
  }
}
