import { NgModule } from "@angular/core";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import * as PlatformConfigReducer from './platform-config-reducer';
import { PlatformConfigEffects } from "@app/shared/store/platform-config/platform-config-effects";

@NgModule({
  imports: [StoreModule.forFeature(PlatformConfigReducer.platformConfigKey,
    PlatformConfigReducer.PlatformConfigReducer),
    EffectsModule.forFeature([PlatformConfigEffects])
  ],
})
export class PlatformConfigStoreModule {}
