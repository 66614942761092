export const ICON_LIST: string[] = [
    'google-logo',
    'blue-location',
    'white-location',
    'location',
    'calendar',
    'check-circle',
    'circle-check',
    'circle-exclamation',
    'close-icon',
    'dropdown-arrow',
    'filter',
    'info-icon',
    'info-icon-2',
    'posts-list',
    'search',
    'verify-icon',
    'building',
    'open-in-new',
    'facebook',
    'instagram',
    'linkedin',
    'bing',
    'twitter',
    'pinterest',
    'tiktok',
    'language',
    'youtube',
    'bookmark',
    'click',
    'settings',
    'chevron-down',
    'chevron-up',
    'error',
    'reply',
    'copy',
    'pencil',
    'ai',
    'message-template',
    'message-light',
    'message-solid',
    'action',
    'green-check',
    'flag',
    'post',
    'more-vert',
    'edit-pen',
    'subscription',
    'duplicate',
    'chat-bubble',
    'happy',
    'neutral',
    'sad',
    'chevron-left',
    'people',
    'delete',
    'toggle_off',
    'person-add',
    'add',
    'person',
    'add_photo_alternate',
    'timer',
    'payments',
    'info',
    'arrow-forward',
    'credit_card',
    'receipt',
    'contract',
    'add_location',
    'done',
    'location_on',
    'analytics',
    'report-problem',
    'clock',
    'flag-solid-blue',
    'opened_blue',
    'star',
    'save-alt',
    'hourglass',
    'chart-outlined',
    'edit',
    'plus',
    'exclamation',
    'mail',
    'call',
    'notes',
    'calendar_clock',
    'save',
    'publish',
    'verified',
    'growth',
    'eye',
    'eyes-slash',
    'get-chosen',
    'refresh',
    'logout',
    'file-csv',
    'file-xls',
    'layers',
    'warning',
    'schedule_send',
    'bag-blue',
    'location-blue',
    'location-pin',
    'info-blue',
    'mail-blue',
    'user-blue',
    'flag-blue',
    'flag-light',
    'star-green',
    'cup-green',
    'arrow-up-regular',
    'arrow-down-regular',
    'thumbs-up',
    'history',
    'reply-clock',
    'calendar_clock',
    'briefcase',
    'waving-hand',
    'tag'
];
