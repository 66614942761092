import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-leave-dialog',
  templateUrl: './leave-dialog.component.html',
  styleUrls: ['./leave-dialog.component.scss'],
})
export class LeaveDialogComponent {
  constructor(public matDialogRef: MatDialogRef<LeaveDialogComponent>) {
    matDialogRef.backdropClick().pipe().subscribe();
  }
}
