import { Injectable, Pipe, PipeTransform } from '@angular/core';
import * as moment from "moment";

@Injectable({
    providedIn: "root",
})
@Pipe({
    name: 'numberTranslate',
    pure: false
})
export class NumberTranslatePipe implements PipeTransform {

    transform(value: number | string | undefined | null): string {
        return value?.toString && typeof value !== 'object' && !Number.isNaN(parseFloat(value.toString()))
            ? moment.unix(parseFloat(value.toString())).format(value.toString())
            : '';
    }

}
