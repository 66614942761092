import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  providers: [CommonModule],
})
export class NotFoundComponent {
  constructor(private translate: TranslateService) {
    this.translate.use(
      navigator.language.match(/en|ar/) ? navigator.language : 'en'
    );
  }
}
