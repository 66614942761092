<div
  class="card"
  [ngClass]="{
    'card-error': hasError,
    'gk-elevation-1': hasElevation,
  }"
  [ngClass]="['p-' + padding, color, classes]"
>
  <ng-content></ng-content>
</div>
