<div class="multi-select" [formGroup]="form" >
  <mat-form-field >
    <mat-label>{{label}}</mat-label>
    <mat-select #multiSelect
                formControlName="selectedOptions"
                (selectionChange)="selectItems($event)" multiple="true" >
      <mat-option *ngFor="let option of selectOptions" [value]="option.value">
        {{ option.label }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <div class="select-tag-container">
    <span class="select-tag" *ngFor="let selectedAllergen of selectedOptions">
      {{  selectedAllergen.label }}
      <button type="button" (click)="removeSelectedOption(selectedAllergen.value)"
              mat-icon-button>
        <mat-icon svgIcon="close-icon"></mat-icon>
      </button>
    </span>
  </div>
</div>
