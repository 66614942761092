import { createAction, props } from "@ngrx/store";
import { GeokPlatformConfig } from "@app/configs/geokTheme";

export const setPlatformConfig = createAction(
  'set PlatformConfig',
);

export const setPlatformConfigSuccess = createAction(
  'set PlatformConfig Success', props<{ platformConfig: GeokPlatformConfig, }>()
);
