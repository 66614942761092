<form class="form" [formGroup]="form" (ngSubmit)="submitForm()">
  <div *ngFor="let field of formFields; let i = index" [style.order]="i">
    @if (!field.isHidden) {
      @if (field.fieldType === FormFieldType.InputText) {
        <mat-form-field class="default">
          <mat-label>{{ field.label }}</mat-label>
          <input
            matInput
            [formControlName]="field.controlName"
            [placeholder]="field.placeholder || ''"
            [maxlength]="field.maxLength || null"
          />
        </mat-form-field>
      }

      @if (field.fieldType === FormFieldType.Textarea) {
        <mat-form-field class="default-material default">
          <mat-label>{{ field.label }}</mat-label>

          <textarea
            [id]="field.id"
            matInput
            [formControlName]="field.controlName"
            [placeholder]="field.placeholder || ''"
            [rows]="field.rows || 5"
            [maxlength]="field.maxLength || null"
          ></textarea>

          @if (field.maxLength) {
            <mat-hint>
              <span class="description-counter">
                <span class="notranslate"
                  >{{
                    form.controls[field.controlName].value?.length || 0
                      | numberTranslate
                  }}
                  / {{ field.maxLength | numberTranslate }}
                </span>
                <span>characters</span>
              </span>
            </mat-hint>
          }
        </mat-form-field>
      }

      @if (field.fieldType === FormFieldType.Dropdown) {
        <mat-form-field class="default">
          <mat-label>{{ field.label }}</mat-label>

          <mat-select
            [id]="field.id"
            [formControlName]="field.controlName"
            [placeholder]="field.placeholder || ''"
          >
            <mat-option
              *ngFor="let option of field.options"
              [value]="option.value"
            >
              {{ option.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      }

      @if (field.fieldType === FormFieldType.DatePicker) {
        <mat-form-field class="default">
          <mat-label>{{ field.label }}</mat-label>

          <input
            matInput
            [matDatepicker]="picker"
            [placeholder]="field.placeholder || ''"
            (click)="picker.open()"
            [formControlName]="field.controlName"
            [min]="field.minDate"
            [max]="field.maxDate"
          />

          <mat-datepicker-toggle
            matIconSuffix
            [for]="picker"
          ></mat-datepicker-toggle>

          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      }

      @if (field.fieldType === FormFieldType.TimePicker) {
        <mat-form-field class="default">
          <mat-label>{{ field.label }}</mat-label>

          <mat-select [formControlName]="field.controlName">
            <mat-option *ngFor="let time of field.timeList" [value]="time">
              <span class="notranslate">{{
                time
                  | datetimeTranslate: { format: "HH:mm", valueFormat: "HH:mm" }
              }}</span>
            </mat-option>
          </mat-select>
        </mat-form-field>
      }

      @if (field.fieldType === FormFieldType.ImageUploader && !readonly) {
        @if (field.inPanel) {
          <mat-accordion>
            <mat-expansion-panel class="mat-elevation-z gk-elevation-1">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <app-icon
                    [iconName]="'add_photo_alternate'"
                    width="25px"
                  ></app-icon>
                  <p class="ms-10px">Add Image</p>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <app-dynamic-image-uploader
                [viewStyle]="field.uploaderStyle"
                [originalImage]="field.defaultValue"
                (imageChosenEmit)="chooseImage($event, field)"
                (deleteImage)="deleteImage(field)"
              ></app-dynamic-image-uploader>
            </mat-expansion-panel>
          </mat-accordion>
        } @else {
          <app-dynamic-image-uploader
            [viewStyle]="field.uploaderStyle"
            [originalImage]="field.defaultValue"
            (imageChosenEmit)="chooseImage($event, field)"
          ></app-dynamic-image-uploader>
        }
      }
    }
  </div>

  @if (!hideSubmitButton) {
    <button
      class="button primary-button"
      [style.order]="'9999999'"
      type="submit"
      [disabled]="form.invalid"
    >
      Submit
    </button>
  }
</form>
