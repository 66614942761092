export interface PaymentResult {
    success: boolean;
    type: PaymentType;
    payment: Payment;
}

export enum PlanTypeEnum {
    EXPERT = 'EXPERT',
    ESSENTIAL = 'ESSENTIAL'
}

export enum PaymentType {
    INSTANT = "INSTANT",
    OFFLINE = "OFFLINE"
}

export enum SubscriptionTypeEnum {
    ONLINE = "ONLINE",
    OFFLINE = "OFFLINE"
}

export enum BillingCycleEnum {
    ANNUALLY = 'YEARLY',
    MONTHLY = 'MONTHLY'
}

export enum InvoiceStatusEnum {
    BILL_CREATED = "BILL_CREATED",
    BILL_EXPIRED = "BILL_EXPIRED",
    BILL_CANCELLED = "BILL_CANCELLED",
    BILL_COLLECTED = "BILL_COLLECTED",
    FAILED = "FAILED",
    BILL_PAID = "BILL_PAID"
}

export enum PaymentDescriptionEnum {
    NEW_SUBSCRIPTION = "NEW_SUBSCRIPTION",
    UPGRADE_TO_EXPERT = "UPGRADE_TO_EXPERT",
    ADDITIONAL_LICENSES = "ADDITIONAL_LICENSES",
    RENEWAL_LICENSES = "RENEWAL",
}

export enum PaymentMethod {
    Sadad = "Sadad",
    wallet = "wallet",
    Mada = "Mada",
}

export interface Payment {
    amount: number;
    billingCycle: BillingCycleEnum;
    companyId: string;
    countryPricingId: string;
    createdAt: string;
    customerId: string;
    description: string;
    expiryDate: string;
    id: string;
    invoiceNumber: number;
    invoiceStatus: InvoiceStatusEnum;
    invoiceUrl: string;
    locationCount: number;
    merchantSessionId: string;
    paidOn: string;
    paymentMethod: PaymentMethod;
    selectedPlan: PlanTypeEnum;
    subscriptionId: string;
    transactionId: string;
    updatedAt: string;
    vat: 0;
}

export interface Invoice {
    status: string,
    description: PaymentDescriptionEnum,
    amount: number;
    total: number;
    vat: number;
    locationCount: number;
    paidOn: string;
    createdAt: string;
    currency: string;
    expiryDate: string;
    invoiceNumber: string;
    invoiceStatus: InvoiceStatusEnum;
    selectedPlan: string;
    url: string;
}

export interface InvoiceLinkResult {
  code: number;
  message: string;
  data: string;
}
