import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import { shareObservable } from "@app/helpers/rxjs-helper";
import { Brand } from "@app/shared/models/brand";
import { BrandsFacade } from "@app/shared/store/brands/brands-facade";
import { Observable } from "rxjs";

@Component({
  selector: 'app-assign-reviewer-dialog',
  templateUrl: './select-brand-dialog.component.html',
  styleUrls: ['./select-brand-dialog.component.scss']
})
export class SelectBrandDialogComponent implements OnInit {
  brands$ : Observable<Brand[]> = new Observable<Brand[]>();
  constructor(
    private brandsFacade: BrandsFacade,
    public matDialogRef: MatDialogRef<SelectBrandDialogComponent>,) {
  }
  close() {
    this.matDialogRef.close();
  }
  ngOnInit() {
    this.brands$ = this.brandsFacade.selectBrands$.pipe(shareObservable());
  }

  selectBrand(brand: Brand) {
    this.matDialogRef.close(brand);
  }
}
