import { createAction, props } from '@ngrx/store';

// update own info
export const loadSideBar = createAction(
    '[Navigation] load side navbar'
);

export const setSideBarVisibility = createAction(
    '[Navigation] set side navbar visibility',
    props<{ visible: boolean }>()
);
export const setIsOnBoarding = createAction(
    '[Navigation] set Is in onboarding',
    props<{ isOnBoarding: boolean }>()
);

export const setIsLoggedInByPlatformAdmin = createAction(
    '[Navigation] set Is logged in by platform admin',
    props<{ isLoggedInByPlatformAdmin: boolean }>()
);
