/// <reference types="@angular/localize" />

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from '@app/app.module';
import { enableProdMode } from "@angular/core";

import * as Sentry from "@sentry/angular-ivy";
import { environment } from 'src/environments/environment';

const env = JSON.parse(JSON.stringify(environment));
if (!location.hostname.includes("localhost")) {
    Sentry.init({
        environment: env.production ? "production" : "develop",
        dsn: `${env.sentry.sentryDSN}`,
        integrations: [
            new Sentry.BrowserTracing({
                tracePropagationTargets: ["localhost", `${env.api}`],
                routingInstrumentation: Sentry.routingInstrumentation,
            }),
            new Sentry.Replay({
                // Additional SDK configuration goes in here, for example:
                maskAllText: false,
                blockAllMedia: false,
            }),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
        // Session Replay
        replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}
if (env.production) {

    enableProdMode();

}
window.LANG = 'en';
window.TRANSIFEX_API = env.transifex_api;

document.dispatchEvent(new CustomEvent('env-loaded', {detail: 'true'}))
platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));

    