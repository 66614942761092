import { ValidatorFn } from '@angular/forms';
import { Image, ImageUploaderStyle } from './data.interface';

export enum FormFieldTypeEnum {
  InputText = 'InputText',
  InputNumber = 'InputNumber',
  Textarea = 'Textarea',
  Dropdown = 'Dropdown',
  DatePicker = 'DatePicker',
  TimePicker = 'TimePicker',
  ImageUploader = 'ImageUploader',
  Button = 'Button',
}

export interface IBaseFieldSettings {
  id: string;
  controlName: string;
  label: string;
  validators?: ValidatorFn[];
  disabled?: boolean;
  defaultValue?: any;
  placeholder?: string;
  isHidden?: boolean;
  isHiddenCondition?: string;
}

export interface IInputTextSettings extends IBaseFieldSettings {
  fieldType: FormFieldTypeEnum.InputText;
  maxLength?: number;
}

export interface IInputNumberSettings extends IBaseFieldSettings {
  fieldType: FormFieldTypeEnum.InputNumber;
}

export interface ITextareaSettings extends IBaseFieldSettings {
  fieldType: FormFieldTypeEnum.Textarea;
  rows?: number;
  maxLength?: number;
}
export interface IButtonFormSettings
  extends Omit<IBaseFieldSettings, 'id' | 'controlName' | 'label'> {
  fieldType: FormFieldTypeEnum.Button;
  onClick?: () => void;
}

export interface IDropdownFormSettings extends IBaseFieldSettings {
  fieldType: FormFieldTypeEnum.Dropdown;
  options: IDropdownOption[];
}

export interface ISingleDatePickerSettings extends IBaseFieldSettings {
  fieldType: FormFieldTypeEnum.DatePicker;
  minDate?: Date;
  maxDate?: Date;
}

export interface ITimePickerSettings extends IBaseFieldSettings {
  fieldType: FormFieldTypeEnum.TimePicker;
  timeList?: string[];
}

export interface IImageUploaderSettings extends IBaseFieldSettings {
  fieldType: FormFieldTypeEnum.ImageUploader;
  uploaderStyle: ImageUploaderStyle;
  minSize?: number;
  originalImage?: Image;
  isLoading?: boolean;
  ratioImageValidation?: boolean;
  sizeImageValidation?: boolean;
  inPanel?: boolean;
  value: string;
}

export interface IDropdownOption {
  label: string;
  value: string | number | boolean;
}

export type FormFieldSettings =
  | (IInputTextSettings & { fieldType: FormFieldTypeEnum.InputText })
  | (ITextareaSettings & { fieldType: FormFieldTypeEnum.Textarea })
  | (IDropdownFormSettings & { fieldType: FormFieldTypeEnum.Dropdown })
  | (ISingleDatePickerSettings & { fieldType: FormFieldTypeEnum.DatePicker })
  | (ITimePickerSettings & { fieldType: FormFieldTypeEnum.TimePicker })
  | (IImageUploaderSettings & { fieldType: FormFieldTypeEnum.ImageUploader });

export interface FormControlState {
  id: string;
  isHidden: boolean;
  isDisabled: boolean;
}
