<div class="navigation-item"
     [ngClass]="{
      'active': active,
      'disabled': item.disabled,
    }"
     [matTooltip]="item.tooltip || ''"
>
    <ng-container *ngIf="item.icon">
        <mat-icon class="navigation-item-icon"
      [svgIcon]="item.icon">
      </mat-icon>
    </ng-container>
</div>


