import { Component, Input, } from '@angular/core';
import { NavigationItem, NavigationType } from '@app/core/components/navigation/navigation.types';
import { Animations } from '@app/shared/animations';

@Component({
    selector: 'app-navigation-collapsable-item',
    templateUrl: './collapsable.component.html',
    styleUrls: ['./collapsable.component.scss'],
    animations: Animations,
})
export class NavigationCollapsableItemComponent {
    navigationType: typeof NavigationType = NavigationType;

    @Input() item!: NavigationItem;
    isCollapsed = true;

    trackByFn(index: number, item: NavigationItem): string {
        return item.id ?? '';
    }
    toggleCollapsable(): void {
        if (this.item.disabled) {
            return;
        }
        this.isCollapsed = !this.isCollapsed;
    }
}
