import { createFeatureSelector, createSelector } from "@ngrx/store";
import { GeokPlatformConfig, GeokPlatformName } from "@app/configs/geokTheme";
import { platformConfigKey, PlatformConfigState } from "@app/shared/store/platform-config/platform-config-reducer";

export const selectPlatformConfigState = createFeatureSelector<PlatformConfigState>(
  platformConfigKey);

export const selectPlatformName = createSelector(
  selectPlatformConfigState,
  (it: PlatformConfigState) => {
    return {
      platformNameAr: it.platformConfig.platformNameAr.replace(/^"(.*)"$/, '$1'),
      platformNameEn: it.platformConfig.platformNameEn.replace(/^"(.*)"$/, '$1'),
    } as GeokPlatformName;
  }
);

export const selectPaymentGateway = createSelector(
    selectPlatformConfigState, (it: PlatformConfigState)=> {
       return it.platformConfig.paymentGateway
    }
);
export const selectPlatformConfig = createSelector(
  selectPlatformConfigState,
  (it: PlatformConfigState) => {
    return {
      ...it.platformConfig,
      platformNameAr: it.platformConfig.platformNameAr.replace(/^"(.*)"$/, '$1'),
      platformNameEn: it.platformConfig.platformNameEn.replace(/^"(.*)"$/, '$1'),
    } as GeokPlatformConfig;
  }
);
