import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ValueSwitcherInterface } from "@app/shared/models/value-switcher.interface";

@Component({
  selector: 'app-value-switcher',
  templateUrl: './value-switcher.component.html',
  styleUrls: ['./value-switcher.component.scss']
})
export class ValueSwitcherComponent {
  @Input() valueOne : ValueSwitcherInterface = {} as ValueSwitcherInterface;
  @Input() valueTwo : ValueSwitcherInterface = {} as ValueSwitcherInterface;
  @Input() selectedValue: ValueSwitcherInterface = this.valueOne;
  @Output() selectValue: EventEmitter<ValueSwitcherInterface> = new EventEmitter<ValueSwitcherInterface>();

  selectValueEmit(event: ValueSwitcherInterface) {
    this.selectedValue = event;
    this.selectValue.emit(event);
  }
}
