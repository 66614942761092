import { createAction, props } from "@ngrx/store";
import { GeoKLocationLight } from "@app/shared/models/geo-k-location";


export const setSelectedBrand = createAction(
  'set selected brand',
  props<{ brandId: string }>()
);

export const setSelectedLocations = createAction(
  'set selected location ids',
  props<{ locationIds: string[] }>()
);
export const setSelectedLocationsObject = createAction(
    'set selected location',
    props<{ locations: GeoKLocationLight[] }>()
);
