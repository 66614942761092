<div style="font-size: xxx-large; text-align: center; margin-top: 100px">
  You don't have access to this page

  <div class="not-found full-background-text">
    <h4>Please check with your company Admin</h4>
    <div class="mt-3" *ngIf="showBackToDashboardButton">
      <button type="button" class="primary-button" routerLink="/dashboard">
        Go back to dashboard
      </button>
    </div>
  </div>
</div>
