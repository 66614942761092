import { Pipe, PipeTransform } from '@angular/core';
import { DateService } from "@app/shared/services/date.service";

@Pipe({
  name: 'toDatePipe',
  pure: false
})
export class ToDatePipe implements PipeTransform {
  constructor(private dateService: DateService) {
  }

  transform(value: {day: number, month: number, year: number}): Date {
    return this.dateService.getDate(value);
  }

}
