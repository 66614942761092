import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { PlatformConfigFacade } from '../store/platform-config/platform-config-facade';
import { PaymentGateway } from '@app/configs/geokTheme';
import { map } from 'rxjs';

export const PaymentGatewayAccessGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot
) => {
  const platformFacade = inject(PlatformConfigFacade);
  const router = inject(Router);

  if (next.data['paymentGatewayAccess']) {
    return platformFacade.selectPaymentGateway$.pipe(
      map((it: PaymentGateway) => {
        const hasAccess = next.data['paymentGatewayAccess'].includes(it);
        if (!hasAccess) {
          router.navigate(['/dashboard/']);
          return false;
        } else {
          return true;
        }
      })
    );
  } else {
    router.navigate(['/dashboard/']);
    return false;
  }
};
