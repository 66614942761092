import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthFacade } from '@app/shared/store/auth/auth.facade';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-no-permission',
  templateUrl: './no-permission.component.html',
  styleUrls: ['./no-permission.component.scss'],
})
export class NoPermissionComponent {
  showBackToDashboardButton: boolean = false;
  constructor(
    private translate: TranslateService,
    private authFacade: AuthFacade
  ) {
    this.authFacade.selectUser$.subscribe(({ userAccesses }) => {
      if (userAccesses.length) {
        this.showBackToDashboardButton = true;
      }
    });

    this.translate.use(
      navigator.language.match(/en|ar/) ? navigator.language : 'en'
    );
  }
}
