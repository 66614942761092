import { InviteUserRolesEnum } from "@app/shared/models/user/invite-user-roles-enum";
import { AccessLevelEnum } from "@app/shared/models/user/access-level-enum";
import { PlanTypeEnum } from "@app/shared/models/payment-result";
import { PaymentGateway } from "@app/configs/geokTheme";

export interface UserAccess {
  accessLevel: AccessLevelEnum;
  accessLevelId: string;
  accessLevelName: string;
  accessLevelRel: string;
  createdAt: string;
  id: string;
  role: InviteUserRolesEnum;
  updatedAt: string;
  userId: string
}

export enum UserAccessRolesEnum {
  COMPANY_ADMIN,
  BRAND_ADMIN,
  ACCESS_GROUP_ADMIN,
  BRAND_SOCIAL_MANAGER,
  ACCESS_GROUP_SOCIAL_MANAGER,
}

export const ALL_MEMBERS: UserAccessRolesEnum[] = [
  UserAccessRolesEnum.COMPANY_ADMIN,
  UserAccessRolesEnum.BRAND_ADMIN,
  UserAccessRolesEnum.ACCESS_GROUP_ADMIN,
  UserAccessRolesEnum.BRAND_SOCIAL_MANAGER,
  UserAccessRolesEnum.ACCESS_GROUP_SOCIAL_MANAGER
];

export const ALL_PLANS: PlanTypeEnum[] = [
  PlanTypeEnum.ESSENTIAL,
  PlanTypeEnum.EXPERT
]

export const ALL_PAYMENT_GATEWAYS: PaymentGateway[] = [
  PaymentGateway.STRIPE,
  PaymentGateway.UPG,
]